import { createFileRoute, redirect } from '@tanstack/react-router';

const CHROME_EXTENSION = import.meta.env.VITE_CHROME_EXTENSION === 'true';

export const Route = createFileRoute('/')({
  beforeLoad: () => {
    if (!CHROME_EXTENSION) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/home',
      });
    }
  },
});
