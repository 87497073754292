export function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const remainingSecondStr = remainingSeconds.toFixed(0);

  const formattedMinutes =
    minutes < 10 ? `0${minutes.toString()}` : minutes.toString();
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSecondStr}` : remainingSecondStr;

  return `${formattedMinutes}:${formattedSeconds}`;
}
