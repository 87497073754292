import { hideExtensionTipAtom } from '@/components/web-extension/hide-tip-atom';
import { logger } from '@/lib/logger';
import { trackLogLoginSuccess } from '@/services/analytics-service';
import { sessionConversionHistoryAtom } from '@/services/audio-service';
import { useLoginMutation } from '@/services/auth-service';
import { useAudioStore, useLocalPrefsStore } from '@/stores/audio-store';
import { useUserStore } from '@/stores/user-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useRouter, useSearch } from '@tanstack/react-router';
import { useAtom } from 'jotai';
import type { Resolver } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import * as z from 'zod';

const formSchema = z.object({
  email: z.string().min(1).max(80),
  password: z.string().min(1).max(80),
});

type FormValues = z.infer<typeof formSchema>;

export function useLoginAndNavigate() {
  const navigate = useNavigate({ from: '/login' });
  const search = useSearch({
    strict: false,
  });
  const redirectTo = 'redirect' in search ? search.redirect : undefined;
  const router = useRouter();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema) as Resolver<FormValues>,
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const loginMutation = useLoginMutation();
  function onSubmit(values: z.infer<typeof formSchema>) {
    loginMutation.mutate(values, {
      onError: () => {
        logger.warn('Login request failed');
        form.setError('root', {
          type: '400',
          message: 'Login failed, please try again.',
        });
      },
      onSuccess: () => {
        trackLogLoginSuccess();
        logger.log(`Login successful, navigating to ${redirectTo ?? '/'}`);
        if (redirectTo) {
          router.history.push(redirectTo);
        } else {
          void navigate({ to: '/' });
        }
      },
    });
  }

  return { form, onSubmit, loginMutation };
}

export function useLogoutAndClearCache() {
  const logout = useUserStore((state) => state.logout);
  const audioPlayer = useGlobalAudioPlayer();
  const [, setHistory] = useAtom(sessionConversionHistoryAtom);
  const [, setHideTip] = useAtom(hideExtensionTipAtom);
  const queryClient = useQueryClient();
  const setAudioItem = useAudioStore((state) => state.setAudioItem);
  const localPrefsStoreReset = useLocalPrefsStore((s) => s.reset);

  return () => {
    audioPlayer.stop();
    logout();
    setHistory([]);
    setHideTip(false);
    setAudioItem(null);
    queryClient.clear();
    void queryClient.resetQueries();
    localPrefsStoreReset();
  };
}
