import { cn } from '@/lib/utils';

export function ErrorComponent({
  header,
  message,
  promptRefresh = false,
  className,
}: {
  header: string;
  message?: string;
  promptRefresh?: boolean;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'mx-auto flex h-full max-w-[33rem] flex-col items-center justify-center',
        className,
      )}
    >
      <div className="flex w-full flex-col items-center justify-center">
        <div className="line-clamp-1 text-center text-2xl font-bold">
          {header}
        </div>
        {message && (
          <div className="line-clamp-2 w-full whitespace-pre-line text-center text-lg">
            {message}
          </div>
        )}
      </div>
      {promptRefresh && (
        <button
          className="mt-4 rounded-full bg-primary px-4 py-2 text-white"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </button>
      )}
    </div>
  );
}
