import { ForceToPlanPageDialog } from '@/components/dialogs/force-to-plan-page-dialog';
import { ModalCmdK } from '@/components/dialogs/modal-cmd-k';
import { UploadDialogsProvider } from '@/components/misc/providers/upload-dialog-providers';
import NavBar from '@/components/nav-bar';
import PlayBar from '@/components/play-bar';
import { Dialog } from '@/components/ui/dialog';
import { logger } from '@/lib/logger';
import { useIsInvalidSubscription } from '@/services/subscription-service';
import { useFeatureFlag } from '@/stores/feature-flag-store';
import { isUserLoggedIn } from '@/stores/user-store';
import {
  Navigate,
  Outlet,
  createFileRoute,
  redirect,
  useMatchRoute,
} from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated')({
  component: Dashboard,
  beforeLoad: ({ location }) => {
    if (!isUserLoggedIn()) {
      logger.log('Not authorized, redirecting to login');

      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/login',
        search: {
          redirect: location.pathname,
        },
      });
    }
  },
});

function Dashboard() {
  const isSubscriptionInvalid = useIsInvalidSubscription();

  const matchRoute = useMatchRoute();
  const isPlanPageActive = matchRoute({ to: '/plan' }) as boolean; //TODO: shouldn't need cast

  const [forceResubscribe] = useFeatureFlag('FORCE_RESUBSCRIBE_DIALOG');
  const shouldShowBlockingDialog =
    isSubscriptionInvalid && !isPlanPageActive && forceResubscribe;

  if (!isUserLoggedIn()) return <Navigate to="/login" />;

  return (
    <div className="grid h-svh w-svw grid-cols-[min-content_1fr] grid-rows-[1fr_min-content] overflow-clip subpixel-antialiased">
      <NavBar />
      <UploadDialogsProvider>
        {!shouldShowBlockingDialog && <ModalCmdK />}
        <main className="h-full overflow-auto">
          <Dialog open={shouldShowBlockingDialog}>
            <Outlet />
            <ForceToPlanPageDialog />
          </Dialog>
        </main>
      </UploadDialogsProvider>
      <PlayBar />
    </div>
  );
}
