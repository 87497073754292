import { logger } from '@/lib/logger';
import { isUserLoggedIn } from '@/stores/user-store';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

const loginSearchParamsSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/login')({
  validateSearch: (search) => loginSearchParamsSchema.parse(search),
  beforeLoad: () => {
    if (isUserLoggedIn()) {
      logger.log('User already logged in, redirect to home');

      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/home',
      });
    }
  },
});
