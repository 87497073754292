import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import * as React from 'react';

import { cn } from '@/lib/utils';

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    viewPortClassName?: string;
    blurY?: boolean | 'top' | 'bottom';
    useBlockFix?: boolean; // https://github.com/radix-ui/primitives/issues/926#issuecomment-1447283516
    useFlexFix?: boolean;
    viewportRef?: React.Ref<HTMLDivElement>; // Add this line
  }
>(
  (
    {
      className,
      children,
      viewPortClassName,
      blurY,
      useBlockFix,
      useFlexFix,
      viewportRef,
      ...props
    },
    ref,
  ) => (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn('relative flex flex-col overflow-hidden', className)}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        ref={viewportRef}
        className={cn(
          `h-full w-full rounded-[inherit]`,
          viewPortClassName ?? '',
          useBlockFix && '[&>div]:!block',
          useFlexFix && '[&>div]:!flex [&>div]:flex-col',
        )}
      >
        {(blurY == true || blurY == 'top') && (
          <div className="absolute inset-x-0 -top-px z-10 h-2 bg-gradient-to-t from-transparent to-background" />
        )}
        {children}
        {(blurY == true || blurY == 'bottom') && (
          <div className="absolute inset-x-0 -bottom-px z-10 h-2 bg-gradient-to-b from-transparent to-background" />
        )}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  ),
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' &&
        'h-full w-2.5 border-l border-l-transparent p-[1px]',
      orientation === 'horizontal' &&
        'h-2.5 flex-col border-t border-t-transparent p-[1px]',
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb
      className={cn('relative flex-1 rounded-full bg-border')}
    />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
