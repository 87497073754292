import { useAudioTimeSec } from '@/lib/audio-utils';
import { ApiException } from '@/lib/exceptions';
import { logger } from '@/lib/logger';
import { useAudioStore } from '@/stores/audio-store';
import { readalongSidebarStateAtom } from '@/stores/read-along-side-bar-atom';
import { useUserStore } from '@/stores/user-store';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { toast } from 'sonner';
import { trackLogNotePadInteraction } from './analytics-service';
import type { ListNotesReqOptions, Note } from './api-service';
import { deleteNoteReq, listNotesReq, upsertNotesReq } from './api-service';
import { useActiveText } from './audio-service';
import {
  useUserPreferencesQuery,
  useUserToken,
  validTokenGuard,
} from './user-service';

export const useListNotesQuery = (
  opts: ListNotesReqOptions,
  refetchInterval?: number,
) => {
  const { token } = useUserToken();
  return useInfiniteQuery({
    queryKey: [listNotesReq.name, token, opts],
    queryFn: ({ pageParam }) =>
      listNotesReq(validTokenGuard(token), {
        ...opts,
        cursorId: pageParam,
      }),
    getNextPageParam: (lastPage) => lastPage.cursorId,
    refetchInterval: refetchInterval ?? 5 * 60 * 1000,
    initialPageParam: '',
  });
};

export const useUpsertNoteMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();

  return useMutation({
    retry: (_, error) => {
      logger.error('Error updating notes', error);
      return false;
    },
    mutationFn: (notes: Omit<Note, 'id'>[]) =>
      upsertNotesReq(validTokenGuard(token), notes),
    onSuccess: (resp) => {
      logger.log('Notes updated successfully');
      const updatedNotes = resp.data;
      const updatedNoteIds = new Set(updatedNotes.map((note) => note.id));
      queryClient.setQueriesData(
        {
          queryKey: [listNotesReq.name, token],
        },
        (
          old:
            | {
                pageParams: unknown;
                pages: {
                  notes: Note[];
                  cursorId: string | null;
                }[];
              }
            | undefined,
        ) => {
          if (!old) return undefined;

          return {
            pageParams: old.pageParams,
            pages: old.pages.map((page, idx) => {
              const updatedPageNotes = page.notes.filter(
                (note) => !updatedNoteIds.has(note.id),
              );
              return {
                notes:
                  idx === 0
                    ? [...updatedNotes, ...updatedPageNotes]
                    : updatedPageNotes,
                cursorId: page.cursorId,
              };
            }),
          };
        },
      );
      return queryClient.invalidateQueries({
        queryKey: [listNotesReq.name, token],
      });
    },
    onError: (err) => {
      logger.error('Error updating notes', err);
    },
  });
};

export const useDeleteNoteMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (noteId: string) =>
      deleteNoteReq({ token: validTokenGuard(token), noteId }),
    onSuccess: (_, noteId) => {
      trackLogNotePadInteraction({ interaction: 'note_deleted' });
      queryClient.setQueriesData(
        {
          queryKey: [listNotesReq.name, token],
        },
        (
          old:
            | {
                pageParams: unknown;
                pages: {
                  notes: Note[];
                  cursorId: string | null;
                }[];
              }
            | undefined,
        ) => {
          if (!old) return undefined;
          return {
            pageParams: old.pageParams,
            pages: old.pages.map((page) => ({
              ...page,
              notes: page.notes.filter((note) => note.id !== noteId),
            })),
          };
        },
      );
      return queryClient.invalidateQueries({
        queryKey: [listNotesReq.name, token],
      });
    },
    onError: (err) => {
      logger.error('Error deleting note', err);
    },
  });
};

export const useAddAudioNote = () => {
  const audioItem = useAudioStore((state) => state.audioItem);
  const chapter = useAudioStore((state) => state.chapter);

  const upsertNoteMutation = useUpsertNoteMutation();
  const [, setReadalongSidebarState] = useAtom(readalongSidebarStateAtom);
  const userId = useUserStore((state) => state.user)?.userId;

  const { data } = useUserPreferencesQuery();
  const activeSentenceText = useActiveText(
    data?.audio_note_capture ?? 'sentence',
  );
  const time = useAudioTimeSec();

  return {
    addAudioNote: () => {
      logger.log('Adding audio note');
      if (!audioItem) {
        logger.error('Audio item not found while adding note');
        toast.error('Error adding audio note, no playing audio');
        return;
      }

      if (!userId) {
        logger.error('User not found while adding note');
        toast.error('Error adding audio note, not logged in');
        return;
      }

      if (!chapter) {
        logger.warn(
          'Could not find chapter while adding audio note, creating custom note instead',
          {
            audioItem,
            chapter,
            userId,
          },
        );
      }
      upsertNoteMutation.mutate(
        [
          {
            audio_conversion_id: audioItem.audioConversionID,
            chapter_id: chapter?.chapter_id ?? undefined,
            title: audioItem.title ?? 'Audio note',
            body: activeSentenceText ?? '',
            user_id: userId,
            chapter_audio_offset_ms: chapter ? time * 1000 : undefined,
          },
        ],
        {
          onSuccess: () => {
            trackLogNotePadInteraction({ interaction: 'note_added' });
            setReadalongSidebarState('notes');
          },
          onError: (e) => {
            toast.error(
              ApiException.getErrorMessage(e, 'Error adding audio note'),
            );
          },
        },
      );
    },
    upsertNoteMutation,
  };
};
