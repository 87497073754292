import PauseIcon from '@/assets/icons/pause.svg?react';
import PlayIcon from '@/assets/icons/play.svg?react';
import {
  flatChildren,
  isProcessingItemStatusInProgress,
  processingItemStatusString,
} from '@/lib/audio-utils';
import { cn, useContextAndErrorIfNull } from '@/lib/utils';
import {
  sessionConversionHistoryAtom,
  useListAudioQuery,
  useProcessingQueueQuery,
} from '@/services/audio-service';
import { useAudioStore } from '@/stores/audio-store';
import { useNavigate } from '@tanstack/react-router';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { CoverImgWithBackground } from '../misc/cover-img-with-background';
import LoadingComponent from '../misc/loading-component';
import {
  AudioLoadContext,
  ensureAudioItem,
} from '../misc/providers/audio-load-provider';
import { SeekBar } from '../play-bar';
import { Button } from '../ui/button';

function RecentConversionBox() {
  const navigate = useNavigate();

  const [history] = useAtom(sessionConversionHistoryAtom);

  const listAudioQuery = useListAudioQuery();

  const processingQueueQuery = useProcessingQueueQuery();

  const latestHistoryConversion = useMemo(() => {
    if (history.length == 0) return null;
    const recent = history
      .filter((item) => item.updated_at_ms > Date.now() - 60 * 60 * 1000)
      .sort((a, b) => b.updated_at_ms - a.updated_at_ms);
    const latest = recent[0];
    return latest;
  }, [history]);

  const processingQueueItem = useMemo(() => {
    if (!processingQueueQuery.data || !latestHistoryConversion) return null;
    const item = processingQueueQuery.data.processingQueue.find(
      (item) => item.id == latestHistoryConversion.id,
    );
    return item;
  }, [processingQueueQuery.data, latestHistoryConversion]);

  const recentAudioItem = useMemo(() => {
    if (!listAudioQuery.data || !latestHistoryConversion) return null;
    const item = listAudioQuery.data.data.find(
      (item) => item.audioConversionID == latestHistoryConversion.id,
    );
    return item;
  }, [listAudioQuery.data, latestHistoryConversion]);

  const { loadAudio } = useContextAndErrorIfNull(AudioLoadContext);
  const audioPlayer = useGlobalAudioPlayer();
  const { togglePlayPause, playing } = audioPlayer;

  const processingStatus = processingQueueItem?.status;

  const isInProgress = isProcessingItemStatusInProgress(processingStatus);
  const humanReadableStatus = processingStatus
    ? processingItemStatusString(processingStatus)
    : null;

  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  useEffect(() => {
    if (
      !latestHistoryConversion?.id ||
      latestHistoryConversion.updated_at_ms < Date.now() - 60 * 2 * 1000
    )
      return;
    setShowUploadSuccess(true);
    setTimeout(() => {
      setShowUploadSuccess(false);
    }, 5000);
  }, [latestHistoryConversion?.id, latestHistoryConversion?.updated_at_ms]);

  const currentAudioItem = useAudioStore((state) => state.audioItem);

  if (
    (!currentAudioItem && !latestHistoryConversion) ||
    processingStatus == 'Error'
  )
    return <></>;

  const audioItem = currentAudioItem ?? recentAudioItem;
  const title =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    audioItem?.title ||
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    processingQueueItem?.title ||
    latestHistoryConversion?.conversionURLOriginal;

  return (
    <div className="flex flex-col gap-2">
      <p
        className={cn(
          'w-full text-center text-sm font-[500] text-[#17B26A] opacity-0 transition-all',
          showUploadSuccess ? 'opacity-100' : 'hidden',
        )}
      >
        Upload successful
      </p>
      <div className="flex flex-col rounded-2xl border-[1.5px] text-[#636363]">
        <div className="flex shrink-0 items-center gap-4 overflow-x-hidden p-4">
          {!isInProgress || audioItem?.cover_img ? (
            <CoverImgWithBackground
              imgSrc={audioItem?.cover_img ?? ''}
              className="size-12 shrink-0 rounded-md"
            />
          ) : (
            <LoadingComponent
              className="size-12 rounded-md"
              spinnerClassName="h-8 w-8"
            />
          )}
          <div className="flex h-full grow flex-col items-start justify-between overflow-x-hidden">
            <p className="w-full truncate pl-1 font-[500] leading-5">{title}</p>
            {!playing && !currentAudioItem ? (
              <Button
                variant="ghost"
                className="flex h-6 gap-1 rounded-full p-1"
                disabled={processingStatus != 'complete'}
                onClick={() => {
                  loadAudio(ensureAudioItem(recentAudioItem));
                }}
              >
                <PlayIcon className="size-3 text-primary" />
                <span className="text-sm font-medium leading-5 text-primary">
                  Play
                </span>
              </Button>
            ) : (
              <div className="flex w-full flex-col items-start gap-1">
                <SeekBar
                  includeTimestamps={false}
                  className="mx-0 mt-1 h-1 w-full"
                  trackClassName="h-1"
                />
                <Button
                  variant="ghost"
                  className="flex h-6 gap-1 rounded-full p-1"
                  disabled={processingStatus != 'complete'}
                  onClick={() => {
                    togglePlayPause();
                  }}
                >
                  {playing ? (
                    <PauseIcon className="size-3 text-primary" />
                  ) : (
                    <PlayIcon className="size-3 text-primary" />
                  )}
                  <span className="text-sm font-medium leading-5 text-primary">
                    {playing ? 'Pause' : 'Play'}
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
        <Button
          variant="ghost"
          disabled={processingStatus != 'complete'}
          onClick={() => {
            if (!audioItem) return;
            loadAudio(audioItem);
            const chapters = flatChildren(audioItem.audioConversion.chapters);

            void navigate({
              to: '/readalong/$audioId/$chapterId',
              params: {
                audioId: audioItem.audioConversionID,
                chapterId: chapters.length > 0 ? chapters[0].chapter_id : '',
              },
            });
          }}
          className="flex h-auto items-center justify-center rounded-b-2xl rounded-t-none border-t  py-2"
        >
          <p className="text-sm font-[500] leading-5 text-primary">
            {isInProgress ? humanReadableStatus : 'or listen in webapp'}
          </p>
        </Button>
      </div>
    </div>
  );
}

export default RecentConversionBox;
