import LockIcon from '@/assets/icons/lock.svg?react';
import { useLogoutAndClearCache } from '@/hooks/login-logout-hook';
import { Link } from '@tanstack/react-router';
import { Button } from '../ui/button';
import { DialogContent } from '../ui/dialog';

export function ForceToPlanPageDialog() {
  const logout = useLogoutAndClearCache();
  return (
    <DialogContent className="flex flex-col items-center gap-6 rounded-3xl p-5">
      <div className="rounded-full bg-[#D43C31] bg-opacity-5 p-2">
        <div className="rounded-full bg-[#D43C31] bg-opacity-5 p-3">
          <LockIcon className="size-6" />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-center text-lg font-semibold">
          Your plan is currently canceled
        </p>
        <p className="text-center text-base font-medium text-[#7A7786]">
          To continue using Listening, please
          <br />
          update your subscription
        </p>
      </div>
      <div className="flex w-full flex-col gap-2">
        <Button className="h-auto self-stretch rounded-full px-6 py-3" asChild>
          <Link className="!text-base font-semibold text-white" to="/plan">
            Subscribe now
          </Link>
        </Button>
        <Button variant="ghost" className="self-center" onClick={logout}>
          Logout
        </Button>
      </div>
    </DialogContent>
  );
}
