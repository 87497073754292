import * as React from 'react';

import { cn } from '@/lib/utils';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const InputVariants = cva('relative', {
  variants: {
    iconPosition: {
      left: ' absolute left-4 top-1/2 -translate-y-1/2 transform text-muted-foreground',
      right:
        ' absolute left-auto right-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
    },
  },
  defaultVariants: {
    iconPosition: 'left',
  },
});

export type InputProps = {
  icon?: React.ReactNode;
  wrapperClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof InputVariants>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, icon, iconPosition, wrapperClassName, ...props },
    ref,
  ) => {
    return (
      <>
        {icon ? (
          <div
            className={cn('relative inline-block h-[52px]', wrapperClassName)}
          >
            {iconPosition !== 'right' && (
              <span className={cn(InputVariants({ iconPosition }))}>
                {icon}
              </span>
            )}
            <input
              type={type}
              className={cn(
                'flex h-full w-full rounded-md border border-input bg-transparent py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                className,
                iconPosition !== 'right' ? 'pl-11 pr-4' : 'pl-4 pr-10',
              )}
              ref={ref}
              {...props}
            />
            {iconPosition === 'right' && (
              <span className={cn(InputVariants({ iconPosition }))}>
                {icon}
              </span>
            )}
          </div>
        ) : (
          <input
            type={type}
            className={cn(
              'flex h-10 w-full rounded-md border border-input bg-transparent px-4 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
              className,
            )}
            ref={ref}
            {...props}
          />
        )}
      </>
    );
  },
);
Input.displayName = 'Input';

export { Input };
