/// <reference types="vite-plugin-svgr/client" />
import CloseNavIcon from '@/assets/icons/closeNav.svg?react';
import FilesIcon from '@/assets/icons/files.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import StickyNoteIcon from '@/assets/icons/stickyNote.svg?react';
import UploadIcon from '@/assets/icons/upload.svg?react';
import logoBig from '@/assets/imgs/listeningLogoLong.png';
import logoSmall from '@/assets/imgs/listeningLogoTransparent.png';
import { cn } from '@/lib/utils';

import { useUploadDropzone } from '@/services/file-upload-service';
import { useUserToken } from '@/services/user-service';
import { useFeatureFlag } from '@/stores/feature-flag-store';
import { Link } from '@tanstack/react-router';
import type { ComponentProps, ComponentType } from 'react';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { UploadDropzone } from './misc/upload-dropzone';
import { Button } from './ui/button';
import { ScrollArea } from './ui/scroll-area';
import { Separator } from './ui/separator';
import { TooltipButton } from './ui/tooltip-button';

type NavBarProps = ComponentProps<'aside'>;
function NavBar({ className, ...props }: NavBarProps) {
  const isSmallScreen = useMediaQuery('(max-width: 1024px)');
  const [collapsed, setCollapsed] = useState(isSmallScreen);

  useEffect(() => {
    setCollapsed(isSmallScreen);
  }, [isSmallScreen]);

  const { isAuthorized } = useUserToken();
  const [showFilesTab] = useFeatureFlag('SHOW_FILES_TAB');
  const [showRecentlyPlayed] = useFeatureFlag('SHOW_RECENTLY_PLAYED');

  if (!isAuthorized) return <></>;

  return (
    <aside
      className={cn(
        `relative row-span-2 flex h-full flex-col items-center border-r border-border bg-background p-4 transition-all duration-500 ${
          collapsed ? 'w-16 sm:w-24' : 'w-72'
        }`,
        className,
      )}
      {...props}
    >
      <Link
        to="/"
        className={cn(
          `relative w-full overflow-x-clip`,
          collapsed && 'flex justify-center',
        )}
      >
        <img
          src={logoSmall}
          alt="Listening logo"
          className={`w-12 object-scale-down py-2 ${
            collapsed ? 'block' : 'hidden'
          }`}
        />
        <img
          src={logoBig}
          alt="Listening logo"
          className={`w-56 min-w-56 object-scale-down p-4 ${
            !collapsed ? 'block' : 'hidden'
          } `}
        />
      </Link>
      <Button
        variant={collapsed ? 'ghost' : 'outline'}
        size="icon"
        className={cn(
          'z-10 h-11 w-11',
          collapsed
            ? 'w-full'
            : 'absolute -right-5 top-6 rounded-full bg-background',
        )}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <CloseNavIcon
          className={cn(`transition duration-500`, collapsed && 'rotate-180')}
        />
      </Button>

      {collapsed && <Separator className="my-2 w-8" />}

      <nav className="flex w-full flex-col items-start gap-1 overflow-x-clip p-0">
        <NavItem
          collapsed={collapsed}
          Icon={HomeIcon}
          label="Homepage"
          linkProps={{ to: '/home' }}
        />
        {showFilesTab && (
          <NavItem
            collapsed={collapsed}
            Icon={FilesIcon}
            label="Files"
            linkProps={{ to: '/' }}
          />
        )}
        <NavItem
          linkProps={{ to: '/notes' }}
          collapsed={collapsed}
          Icon={StickyNoteIcon}
          label="Notes"
        />
      </nav>

      {!collapsed && showRecentlyPlayed && <RecentlyPlayedSection />}
      {collapsed ? (
        <CollapsedUploadButton />
      ) : (
        <UploadDropzone location="nav_side_bar_dropzone" showInProgressToast />
      )}
    </aside>
  );
}

export default NavBar;

function NavItem({
  Icon,
  label,
  collapsed,
  linkProps,
}: {
  Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
  label: string;
  collapsed: boolean;
  linkProps: ComponentProps<typeof Link>;
}) {
  return (
    <TooltipButton
      toolTipEnabled={collapsed}
      toolTipContent={label}
      variant="ghost"
      className={`flex h-12 w-full p-4 ${
        collapsed ? 'justify-center' : 'justify-start'
      }`}
      asChild
    >
      <Link {...linkProps}>
        <Icon className="min-w-[18px]" height={18} width={18} />
        {!collapsed && (
          <strong className="ml-4 text-base font-[600] leading-6 text-[#4A5578]">
            {label}
          </strong>
        )}
      </Link>
    </TooltipButton>
  );
}

function RecentlyPlayedItem({ label }: { label: string }) {
  return (
    <Button
      variant="ghost"
      className="flex h-14 w-full snap-start items-center justify-start gap-4 rounded-lg p-4"
    >
      <div className="size-9 shrink-0 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 pr-2"></div>
      <p className="shrink text-ellipsis">{label}</p>
    </Button>
  );
}

function RecentlyPlayedSection({ className, ...props }: ComponentProps<'div'>) {
  return (
    <section
      className={cn(
        ' flex w-full flex-grow flex-col overflow-hidden',
        className,
      )}
      {...props}
    >
      <h6 className="truncate px-4 pt-2 font-[500] text-secondary-foreground">
        Recently Played
      </h6>
      <ScrollArea
        className="size-full"
        viewPortClassName="snap-y scroll-py-1"
        blurY={true}
      >
        <div className="p-1">
          <RecentlyPlayedItem label="YOLO v3 model video class..." />
          <RecentlyPlayedItem label="YOLO v3 model video class..." />
          <RecentlyPlayedItem label="YOLO v3 model video class..." />
        </div>
      </ScrollArea>
    </section>
  );
}

function CollapsedUploadButton() {
  const { getRootProps, getInputProps, isDragActive } = useUploadDropzone({
    showInProgressToast: true,
    location: 'nav_side_bar_collapsed_dropzone',
  });

  return (
    <TooltipButton
      {...getRootProps()}
      toolTipContent="Upload file"
      className={cn(
        `mt-auto size-10 rounded-full p-2 transition-all `,
        isDragActive && 'size-12 drop-shadow-xl',
      )}
    >
      <input {...getInputProps()} />
      <UploadIcon className="size-4 shrink-0 [&>path]:stroke-background" />
    </TooltipButton>
  );
}
