import { useLogoutAndClearCache } from '@/hooks/login-logout-hook';
import { logger } from '@/lib/logger';
import { useUserStore } from '@/stores/user-store';
import { useMutation } from '@tanstack/react-query';
import { identifyLogUser } from './analytics-service';
import {
  deleteAccountReq,
  loginReq,
  postCancelSurveyReq,
  registerReq,
  registerWithStripeSetupIntentReq,
} from './api-service';
import { useUserToken, validTokenGuard } from './user-service';

export const useLoginMutation = () => {
  const login = useUserStore((state) => state.login);
  return useMutation({
    retry: false,
    mutationFn: (params: { email: string; password: string }) =>
      loginReq(params.email, params.password),
    onSuccess: (data) => {
      logger.log('Login successful');
      login({
        email: data.email,
        token: data.Authorization,
        rssUrl: data.podcastURL,
        userId: data._id,
      });
      identifyLogUser({
        email: data.email,
        userId: data._id,
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useRegisterMutation = () => {
  const login = useUserStore((state) => state.login);
  return useMutation({
    mutationFn: (params: { email: string; password: string }) =>
      registerReq(params.email, params.password),
    onSuccess: (data) => {
      logger.log('Registration successful');
      login({
        email: data.email,
        token: data.Authorization,
        rssUrl: data.podcastURL,
        userId: data._id,
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useRegisterWithStripeMutation = () => {
  const login = useUserStore((state) => state.login);
  return useMutation({
    mutationFn: (params: {
      email: string;
      password: string;
      stripeSetupIntentId: string;
      growsurfReferrerId?: string;
      utmParams?: Record<string, string>;
    }) => registerWithStripeSetupIntentReq(params),
    onSuccess: (data) => {
      logger.log('Registration successful');
      login({
        email: data.email,
        token: data.Authorization,
        rssUrl: data.podcastURL,
        userId: data._id,
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useDeleteAccountMutation = () => {
  const logout = useLogoutAndClearCache();
  const { token } = useUserToken();
  const email = useUserStore((state) => state.user)?.email;

  return useMutation({
    mutationFn: async ({
      cancelReason,
      likedFeatures,
    }: {
      cancelReason: string;
      likedFeatures: string;
    }) => {
      void postCancelSurveyReq(validTokenGuard(token), {
        email: validTokenGuard(email),
        is_user_deleted: false,
        user_cancel_reason: cancelReason,
        user_liked_features: likedFeatures,
      });
      await deleteAccountReq(validTokenGuard(token));
    },
    onSuccess: () => {
      logger.log('Delete account successful');
      logout();
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};
