import UploadIcon from '@/assets/icons/upload.svg?react';
import { cn } from '@/lib/utils';
import type { trackLogUploadAudioInteraction } from '@/services/analytics-service';
import { useUploadDropzone } from '@/services/file-upload-service';
import { Button } from '../ui/button';

export function UploadDropzone({
  className,
  showButton = true,
  showInProgressToast,
  location,
}: {
  className?: string;
  showButton?: boolean;
  showInProgressToast?: boolean;
  location: Parameters<typeof trackLogUploadAudioInteraction>[0]['location'];
}) {
  const { getRootProps, getInputProps, isDragActive } = useUploadDropzone({
    showInProgressToast,
    location,
  });

  return (
    <div
      className={cn(
        'mt-auto h-52 w-full shrink overflow-x-clip rounded-xl px-4 pt-3',
        className,
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div
        className={cn(
          `flex size-full flex-col items-center justify-evenly rounded-2xl border-4 border-dashed bg-muted py-3 text-muted-foreground`,
          isDragActive && 'bg-secondary underline',
        )}
      >
        <UploadIcon className="size-6 " />
        <strong className="text-center text-xl font-[700]">Upload</strong>
        <p className="pb-1 text-center text-xs font-[500]">Drag and drop</p>
        {showButton && <Button className="rounded-full">Upload file</Button>}
      </div>
    </div>
  );
}
