import CheckIcon from '@/assets/icons/circleCheck.svg?react';
import ErrorIcon from '@/assets/icons/error.svg?react';
import FileIcon from '@/assets/icons/file.svg?react';
import { cn } from '@/lib/utils';
import type { trackLogUploadAudioInteraction } from '@/services/analytics-service';
import type { FileUploadStatus } from '@/services/file-upload-service';
import {
  useFileUploadStore,
  useUploadDropzone,
} from '@/services/file-upload-service';
import type { HTMLAttributes } from 'react';
import Spinner from '../misc/spinner';
import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';

export function FileUploadScrollable({
  className,
  scrollAreaClassName,
  location,
  ...props
}: {
  className?: string;
  scrollAreaClassName?: string;
  location: Parameters<typeof trackLogUploadAudioInteraction>[0]['location'];
} & HTMLAttributes<HTMLDivElement>) {
  const { onDrop } = useUploadDropzone({
    showInProgressToast: false,
    location,
  });

  const uploads = useFileUploadStore((state) => state.uploads);

  function fileTypeToColor(fileType: string) {
    const lowerFileType = fileType.toLowerCase();
    if (lowerFileType == 'pdf') return '#D92D20';
    if (lowerFileType == 'docx' || lowerFileType == 'doc') return '#3b82f6';
    if (lowerFileType == 'mobi' || lowerFileType == 'epub') return '#22c55e';
    return '#D92D20';
  }

  function humanReadableFileSize(byteLen: number) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (byteLen > 1024) {
      byteLen /= 1024;
      i++;
    }
    return `${byteLen.toFixed(2)} ${units[i]}`;
  }

  function statusString(status: FileUploadStatus, progress: number) {
    if (status == 'pending') return 'Pending';
    if (status == 'uploadToS3') return `${progress.toString()}% uploaded`;
    if (status == 'createUrl') return 'Creating URL';
    if (status == 'requestingConversion') return 'Requesting conversion';
    return 'Upload Complete';
  }

  return (
    <div
      className={cn('flex w-full shrink flex-col overflow-hidden', className)}
      {...props}
    >
      <ScrollArea
        className={cn('flex h-full flex-col px-4', scrollAreaClassName)}
        viewPortClassName="h-full"
        blurY
      >
        <div className="mb-2 flex h-full flex-col gap-2 py-2">
          {uploads.map((upload, idx) => {
            let fileExt = upload.file.name.split('.').pop();
            if (!fileExt) fileExt = 'file';
            fileExt = fileExt.toUpperCase();
            return (
              <div
                key={`${upload.file.name}-${idx.toString()}`}
                className={cn(
                  `grid w-full grid-cols-[min-content_1fr_min-content] grid-rows-2 items-center gap-x-2 rounded-xl border p-2`,
                  upload.status == 'error' && 'bg-[#f9f1f0]',
                )}
              >
                <div className="relative row-span-2 ">
                  <FileIcon style={{ color: fileTypeToColor(fileExt) }} />
                  <span className="absolute bottom-2 w-full px-1 text-center text-[9px] font-[700] text-white">
                    {fileExt}
                  </span>
                </div>
                <span className="font-[600]">{upload.file.name}</span>
                {upload.status == 'done' ? (
                  <CheckIcon className="row-span-2 mr-2 size-6" />
                ) : upload.status == 'error' ? (
                  <ErrorIcon className="row-span-2 mr-2 size-6" />
                ) : (
                  <Spinner className="row-span-2 mr-2 size-6" />
                )}
                {upload.status == 'error' ? (
                  <div>
                    <span>Upload error</span>
                    <Button
                      variant="link"
                      className="h-min py-0"
                      onClick={() => {
                        onDrop([new File([upload.file], upload.file.name)]);
                      }}
                    >
                      Try again
                    </Button>
                  </div>
                ) : (
                  <span className="truncate">
                    {humanReadableFileSize(upload.file.size)} -{' '}
                    {statusString(upload.status, upload.progress)}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </ScrollArea>
    </div>
  );
}
