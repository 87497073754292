import { ErrorComponent } from '@/components/misc/error-component';
import { logger } from '@/lib/logger';
import { useUserToken } from '@/services/user-service';
import type { ErrorComponentProps } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

const isDev = import.meta.env.DEV;

function ErrorPage(props: ErrorComponentProps) {
  const error = props.error;
  const navigate = useNavigate();
  const { isAuthorized } = useUserToken();

  const status = (error as { status?: number }).status; //TODO: fix this, no longer valid after switching to tanstack

  useEffect(() => {
    logger.log('Checking auth');
    if (!isAuthorized && status === 401) {
      logger.log('Not authorized, redirecting to login');
      if (!isDev) void navigate({ to: '/login' });
    }
  }, [navigate, isAuthorized, status]);

  if (status === 404) {
    return <div>This page doesn&apos;t exist.</div>;
  }

  if (status === 401) {
    return <div>You aren&apos;t authorized to see this page.</div>;
  }

  if (status === 503) {
    return <div>Server error occurred.</div>;
  }

  if (status === 418) {
    return <div>🫖</div>;
  }

  return <ErrorComponent header="Something went wrong" />;
}

export default ErrorPage;
