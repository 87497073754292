import type { AudioItemDTO, ChapterDTO } from '@/lib/audio-utils';
import { DEFAULT_TITLE, flatChildren, getAudioUrl } from '@/lib/audio-utils';
import { logger } from '@/lib/logger';
import { useContextAndErrorIfNull } from '@/lib/utils';
import {
  trackLogStartedPlayingAudio,
  trackLogStoppedPlayingAudio,
} from '@/services/analytics-service';
import { useAudioStore, useLocalPrefsStore } from '@/stores/audio-store';
import { useNavigate } from '@tanstack/react-router';
import React, { useCallback, useEffect, useState } from 'react';
import type { AudioLoadOptions } from 'react-use-audio-player';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

function useLoadAudioItem() {
  const { load, looping } = useGlobalAudioPlayer();
  useTrackPlayTime();
  const playbackRate = useLocalPrefsStore((state) => state.playbackRate);
  const volume = useLocalPrefsStore((state) => state.volume);
  const setAudioItem = useAudioStore((state) => state.setAudioItem);
  // const currentAudioItem = useAudioStore((state) => state.audioItem);

  const [ended, setEnded] = useState(false);

  useEffect(() => {
    onend(load, looping, setEnded, ended);
  }, [ended, load, looping]);

  return {
    loadAudioItem: (
      audioItem: AudioItemDTO,
      chapter?: ChapterDTO,
      options?: AudioLoadOptions,
    ) => {
      try {
        const firstSrc = getAudioUrl({ audioItem, chapter });

        load(firstSrc, {
          autoplay: true,
          ...options,
          html5: true,
          initialRate: clamp(playbackRate, 0.01, 16),
          initialVolume: clamp(volume, 0, 1),
          onend: () => {
            logger.log('Audio ended');
            setEnded(true);
          },
        });
        setAudioItem(audioItem, chapter);
      } catch (error) {
        logger.error('Error while loading audio', error);
        setAudioItem(null, null);
        throw error;
      }
    },
  };
}

function onend(
  load: (src: string, options?: AudioLoadOptions) => void,
  looping: boolean,
  setEnded: (ended: boolean) => void,
  ended: boolean,
) {
  if (!ended) return;
  setEnded(false);
  if (looping) return;

  const audioItem = useAudioStore.getState().audioItem;
  if (audioItem === null) {
    logger.log('No audio item, not playing next chapter');
    return;
  }

  logger.log(`Finished playing ${audioItem.title ?? DEFAULT_TITLE}`);
  const chapter = useAudioStore.getState().chapter;
  if (chapter === null) {
    logger.log('No chapter selected, not playing next chapter');
    return;
  }
  const flatChapters = flatChildren(audioItem.audioConversion.chapters);
  const chapterIdx = flatChapters.findIndex(
    (c) => c.chapter_id === chapter.chapter_id,
  );

  const shuffle = useAudioStore.getState().shuffle;

  if (!shuffle && chapterIdx === flatChapters.length - 1) {
    logger.log('Last chapter, not playing next chapter');
    return;
  }
  const nextChapterIdx = shuffle
    ? Math.floor(Math.random() * flatChapters.length)
    : chapterIdx + 1;

  const nextChapter = flatChapters[nextChapterIdx];
  useAudioStore.getState().setChapter(nextChapter);
  load(nextChapter.audio_url ?? nextChapter.audio_preview_url, {
    html5: true,
    autoplay: true,
    initialRate: useLocalPrefsStore.getState().playbackRate,
    initialVolume: useLocalPrefsStore.getState().volume,

    onend: () => {
      setEnded(true);
    },
  });
}

type LoadFunction = ReturnType<typeof useLoadAudioItem>['loadAudioItem'];
export const AudioLoadContext = React.createContext<{
  loadAudio: LoadFunction;
  // We know that all the access to this will be within the provider
} | null>(null);

export const AudioLoadProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { loadAudioItem } = useLoadAudioItem();
  return (
    <AudioLoadContext.Provider value={{ loadAudio: loadAudioItem }}>
      {children}
    </AudioLoadContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export function ensureAudioItem(audioItem?: AudioItemDTO | null) {
  if (!audioItem) throw new Error('Audio item is null');
  return audioItem;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useLoadAudioAndNavigate() {
  const { loadAudio } = useContextAndErrorIfNull(AudioLoadContext);
  const currentAudioItem = useAudioStore((state) => state.audioItem);

  const navigate = useNavigate();
  return (audioItem: AudioItemDTO, chapter?: ChapterDTO) => {
    const chapters = flatChildren(audioItem.audioConversion.chapters);
    const selectedChapter =
      chapter ?? chapters.length > 0 ? chapters[0] : undefined;
    if (audioItem.audioConversionID !== currentAudioItem?.audioConversionID)
      loadAudio(audioItem, selectedChapter);
    void navigate({
      to: '/readalong/$audioId/$chapterId',
      params: {
        audioId: audioItem.audioConversionID,
        chapterId: selectedChapter?.chapter_id ?? '',
      },
    });
  };
}

function useTrackPlayTime() {
  const { playing, rate, duration, getPosition } = useGlobalAudioPlayer();
  const audioItem = useAudioStore((state) => state.audioItem);
  const chapter = useAudioStore((state) => state.chapter);

  const [sessionDetails, setSessionDetails] = useState<null | {
    audioItem: AudioItemDTO;
    chapter: ChapterDTO | null;

    sessionId: string;
    startTime: number;
    rate: number;
  }>(null);

  const startSession = useCallback(() => {
    if (!audioItem) {
      logger.error('No audio item found, cannot start play time session');
      return;
    }
    if (!playing) {
      logger.error('Audio is not playing, cannot start play time session');
      return;
    }
    const sessionDetails = {
      audioItem,
      chapter,
      sessionId: crypto.randomUUID(),
      startTime: Date.now(),
      rate,
    };
    setSessionDetails(sessionDetails);
    trackLogStartedPlayingAudio({
      audio_item_id: audioItem.audioConversionID,
      chapter_id: chapter?.chapter_id,
      chapter_title: chapter?.title,
      listen_session_id: sessionDetails.sessionId,
      playback_rate: rate,
      audio_duration_sec: duration,
      starting_position_sec: getPosition(),
    });
  }, [audioItem, chapter, duration, getPosition, playing, rate]);

  const endSession = useCallback(() => {
    if (!sessionDetails) {
      logger.error('No session details found, cannot end play time session');
      return;
    }
    const endTime = Date.now();
    const duration = (endTime - sessionDetails.startTime) * sessionDetails.rate;
    const rateModifiedDuration = rate * duration;
    trackLogStoppedPlayingAudio({
      audio_item_id: sessionDetails.audioItem.audioConversionID,
      chapter_id: sessionDetails.chapter?.chapter_id,
      listen_session_id: sessionDetails.sessionId,
      playback_rate: sessionDetails.rate,
      rate_modified_duration_sec: rateModifiedDuration / 1000,
      real_world_duration_sec: duration / 1000,
    });
    setSessionDetails(null);
  }, [rate, sessionDetails]);

  const restartSession = useCallback(() => {
    endSession();
    startSession();
  }, [endSession, startSession]);

  useEffect(() => {
    if (playing && !sessionDetails) {
      startSession();
    } else if (!playing && sessionDetails) {
      endSession();
    } else if (sessionDetails && rate !== sessionDetails.rate) {
      restartSession();
    }
  }, [endSession, playing, rate, restartSession, sessionDetails, startSession]);
}
