import { Providers } from '@/components/misc/providers/providers';
import ErrorPage from '@/components/pages/other/error-page';
import { createRootRoute, Navigate, Outlet } from '@tanstack/react-router';

export const Route = createRootRoute({
  errorComponent: ErrorPage,
  component: () => (
    <>
      <Providers>
        <Outlet />
      </Providers>
    </>
  ),
  notFoundComponent: () => <Navigate to="/home" />,
});
