import {
  isChapterBased,
  type AudioItemDTO,
  type ChapterDTO,
} from '@/lib/audio-utils';
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type AudioStoreState = {
  audioItem: AudioItemDTO | null;
  chapter: ChapterDTO | null;
  setAudioItem: (
    audioItem: AudioItemDTO | null,
    chapter?: ChapterDTO | null,
  ) => void;
  setChapter: (chapter: ChapterDTO) => void;
  setShuffle: (shuffle: boolean) => void;
  shuffle: boolean;
};

export type FontSize = 'small' | 'medium' | 'large';

export const useAudioStore = create<AudioStoreState>()(
  devtools((set) => ({
    audioItem: null,
    chapter: null,
    setAudioItem: (
      audioItem: AudioItemDTO | null,
      chapter?: ChapterDTO | null,
    ) => {
      set({ audioItem });
      if (audioItem == null) {
        set({ chapter: null });
        return;
      }
      if (!isChapterBased(audioItem)) {
        set({ chapter: null });
        return;
      } else
        set({
          chapter: chapter ?? audioItem.audioConversion.chapters.children[0],
        });
    },
    setChapter: (chapter: ChapterDTO) => {
      set({ chapter });
    },
    setShuffle: (shuffle: boolean) => {
      set({ shuffle });
    },
    shuffle: false,
  })),
);

type LocalPrefsState = {
  setPlaybackRate: (playbackRate: number) => void;
  playbackRate: number;
  setVolume: (volume: number) => void;
  volume: number;
  setFontSize: (fontSize: FontSize) => void;
  fontSize: FontSize;
  reset: () => void;
  readAlongViewer: 'normal' | 'pdf';
  setReadAlongViewer: (viewer: 'normal' | 'pdf') => void;
  pdfScale: number;
  setPdfScale: (scale: number) => void;
  autoScroll: boolean;
  setAutoScroll: (autoScroll: boolean) => void;
};

export const useLocalPrefsStore = create<LocalPrefsState>()(
  persist(
    (set) => ({
      setPlaybackRate: (playbackRate: number) => {
        set({ playbackRate });
      },
      playbackRate: 1,
      setVolume: (volume: number) => {
        set({ volume });
      },
      volume: 1,
      setFontSize: (fontSize: FontSize) => {
        set({ fontSize });
      },
      fontSize: 'medium',
      reset: () => {
        set({ playbackRate: 1, volume: 1, fontSize: 'medium' });
      },
      readAlongViewer: 'pdf',
      setReadAlongViewer: (viewer: 'normal' | 'pdf') => {
        set({ readAlongViewer: viewer });
      },
      pdfScale: 1,
      setPdfScale: (scale: number) => {
        set({ pdfScale: scale });
      },
      autoScroll: false,
      setAutoScroll: (autoScroll: boolean) => {
        set({ autoScroll });
      },
    }),
    {
      name: 'audio-prefs-storage',
    },
  ),
);
