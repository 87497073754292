import { ApiException } from '@/lib/exceptions';
import { logger } from '@/lib/logger';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'sonner';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { trackLogUploadAudioInteraction } from './analytics-service';
import {
  convertUrlAudioReq,
  createPdfUploadUrlReq,
  getProcessingQueueReq,
  uploadFileToS3Req,
} from './api-service';
import { sessionConversionHistoryAtom } from './audio-service';
import { useUserToken, validTokenGuard } from './user-service';

export type FileUploadStatus =
  | 'pending'
  | 'createUrl'
  | 'uploadToS3'
  | 'requestingConversion'
  | 'done'
  | 'error';
export type FileUploadJob = {
  file: File;
  progress: number;
  status: FileUploadStatus;
};

type FileUploadStoreState = {
  uploads: FileUploadJob[];
  addUpload: (upload: FileUploadJob) => void;
  removeUpload: (upload: FileUploadJob) => void;
  updateUpload: (upload: FileUploadJob) => void;
};

export const useFileUploadStore = create<FileUploadStoreState>()(
  devtools((set) => ({
    uploads: [],
    addUpload: (upload) => {
      set((state) => ({
        uploads: [...state.uploads, upload],
      }));
    },
    removeUpload: (upload) => {
      set((state) => ({
        uploads: state.uploads.filter((u) => u.file !== upload.file),
      }));
    },
    updateUpload: (upload) => {
      set((state) => ({
        uploads: state.uploads.map((u) =>
          u.file === upload.file ? upload : u,
        ),
      }));
    },
  })),
);

export const useMultiFileUploadMutation = (token: string) => {
  const { addUpload, updateUpload } = useFileUploadStore();
  const queryClient = useQueryClient();
  const [, setHistory] = useAtom(sessionConversionHistoryAtom);

  return useMutation({
    mutationFn: async (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        addUpload({ file, progress: 0, status: 'pending' });
      });
      for (const file of acceptedFiles) {
        try {
          updateUpload({ file, progress: 0, status: 'createUrl' });
          const filename = file.name;
          const { signed_upload_url: signedUrl, fields } =
            await createPdfUploadUrlReq(filename, token);
          updateUpload({ file, progress: 0, status: 'uploadToS3' });
          const url = await uploadFileToS3Req(
            file,
            signedUrl,
            fields,
            (p) => {
              updateUpload({ file, progress: p, status: 'uploadToS3' });
            },
            1000 * 60 * 5,
          );
          updateUpload({ file, progress: 100, status: 'requestingConversion' });
          const data = await convertUrlAudioReq(url, token);
          setHistory((history) => [...history, data]);
          updateUpload({ file, progress: 100, status: 'done' });
        } catch (error) {
          logger.error(`File upload error: `, error);
          updateUpload({ file, progress: 0, status: 'error' });
          throw error;
        }
      }
    },
    onSuccess: async (_, acceptedFiles) => {
      logger.log(
        `Successfully uploaded ${acceptedFiles.length.toString()} files`,
      );
      await queryClient.refetchQueries({
        queryKey: [getProcessingQueueReq.name],
      });
    },
    onError: (error) => {
      logger.error(error);
    },
    networkMode: 'always',
  });
};

export const useUploadDropzone = ({
  showInProgressToast,
  location,
}: {
  showInProgressToast?: boolean;
  location: Parameters<typeof trackLogUploadAudioInteraction>[0]['location'];
}) => {
  const { token } = useUserToken();
  const uploadMutation = useMultiFileUploadMutation(validTokenGuard(token));

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      trackLogUploadAudioInteraction({
        interaction: 'file_dropzone',
        location,
        files: acceptedFiles.map((file) => ({
          lastModified: file.lastModified,
          name: file.name,
          size: file.size,
          type: file.type,
        })),
      });
      const fileString = acceptedFiles.length > 1 ? 'files' : 'file';
      const loadingToast = showInProgressToast
        ? toast.loading(
            `Uploading ${acceptedFiles.length.toString()} ${fileString}`,
          )
        : undefined;

      uploadMutation.mutate(acceptedFiles, {
        onSuccess: () =>
          toast.success(
            `Added ${acceptedFiles.length.toString()} ${fileString} to your library`,
          ),
        onError: (e) =>
          toast.error(
            ApiException.getErrorMessage(
              e,
              `There was an error uploading your ${fileString}`,
            ),
          ),
        onSettled: () => {
          if (loadingToast) toast.dismiss(loadingToast);
          uploadMutation.reset();
        },
      });
    },
    [location, showInProgressToast, uploadMutation],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/msword': ['.doc'],
      'application/epub+zip': ['.epub'],
      'application/x-mobipocket-ebook': ['.mobi'],
      'text/html': ['.html', '.htm'],
      'text/plain': ['.txt'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
      'application/rtf': ['.rtf'],
    },
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    onDrop,
    uploadMutation,
  };
};
