import { clsx, type ClassValue } from 'clsx';
import { useContext, type Context } from 'react';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isError(err: unknown): err is Error {
  return err instanceof Error;
}

export const CHROME_EXTENSION =
  import.meta.env.VITE_CHROME_EXTENSION === 'true';
export const IS_TAURI = '__TAURI__' in window;

export const envString = `${import.meta.env.MODE}${CHROME_EXTENSION ? '-chrome-extension' : ''}${IS_TAURI ? `-tauri` : ''}`;

export const useContextAndErrorIfNull = <ItemType>(
  context: Context<ItemType | null>,
): ItemType => {
  const contextValue = useContext(context);
  if (contextValue === null) {
    throw Error('Context has not been Provided!');
  }
  return contextValue;
};

export function raiseIfFalsy<T>(
  value: T | undefined,
  message?: string,
): NonNullable<T> {
  if (!value) {
    throw new Error(message ?? 'Unexpected falsy value');
  }
  return value;
}
