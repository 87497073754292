import { cn } from '@/lib/utils';
import Spinner from './spinner';

function LoadingComponent({
  className,
  spinnerClassName,
}: {
  className?: string;
  spinnerClassName?: string;
}) {
  return (
    <div
      className={cn(
        'flex h-full w-full flex-col items-center justify-center',
        className,
      )}
    >
      <Spinner className={spinnerClassName} />
    </div>
  );
}

export default LoadingComponent;
