'use client';

import LinkIcon from '@/assets/icons/link.svg?react';
import LogoutIcon from '@/assets/icons/logout.svg?react';
import PageIcon from '@/assets/icons/page.svg?react';
import PlanIcon from '@/assets/icons/plan.svg?react';
import ProfileIcon from '@/assets/icons/profile.svg?react';
import ReferIcon from '@/assets/icons/refer.svg?react';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';

import { useLogoutAndClearCache } from '@/hooks/login-logout-hook';
import { flatChildren } from '@/lib/audio-utils';
import { useContextAndErrorIfNull } from '@/lib/utils';
import { useListAudioQuery } from '@/services/audio-service';
import type { LinkOptions } from '@tanstack/react-router';
import { Link, useNavigate } from '@tanstack/react-router';
import type { PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { CoverImgWithBackground } from '../misc/cover-img-with-background';
import { AudioLoadContext } from '../misc/providers/audio-load-provider';
import { useUploadDialogs } from '../misc/providers/upload-dialog-providers';

export function ModalCmdK() {
  const [open, setOpen] = useState(false);

  useHotkeys(
    ['ctrl+k', 'meta+k'],
    () => {
      setOpen(!open);
    },
    {
      preventDefault: true,
    },
    [setOpen],
  );

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const logout = useLogoutAndClearCache();
  const { setCurrentOpenDialog } = useUploadDialogs();

  const listAudioQuery = useListAudioQuery();

  const { loadAudio } = useContextAndErrorIfNull(AudioLoadContext);
  const navigate = useNavigate();

  return (
    <>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Audio">
            {listAudioQuery.data?.data.map((audio) => (
              <CommandItem
                key={audio.audioConversionID}
                onSelect={() => {
                  loadAudio(audio);
                  const chapters = flatChildren(audio.audioConversion.chapters);
                  void navigate({
                    to: '/readalong/$audioId/$chapterId',
                    params: {
                      audioId: audio.audioConversionID,
                      chapterId:
                        chapters.length > 0 ? chapters[0].chapter_id : '',
                    },
                  });
                  closeDialog();
                }}
              >
                <CoverImgWithBackground
                  imgSrc={audio.cover_img}
                  className="mr-2 size-12 rounded-2xl"
                  imgClassName="mt-2"
                />
                <p className="">{audio.title}</p>
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Upload">
            <CommandItem
              onSelect={() => {
                closeDialog();
                setCurrentOpenDialog('file');
              }}
            >
              <PageIcon className="mr-2 size-4" />
              <span>Upload a file</span>
            </CommandItem>
            <CommandItem
              onSelect={() => {
                closeDialog();
                setCurrentOpenDialog('url');
              }}
            >
              <LinkIcon className="mr-2 size-4" />
              <span>Link to web page or PDF</span>
            </CommandItem>
            <CommandItem
              onSelect={() => {
                closeDialog();
                setCurrentOpenDialog('email');
              }}
            >
              <ReferIcon className="mr-2 size-4" />
              <span>Forward an email</span>
            </CommandItem>
          </CommandGroup>
          <CommandGroup heading="Settings">
            <LinkCommandItem closeDialog={closeDialog} to="/profile">
              <ProfileIcon className="mr-2 size-4" />
              <span>Profile</span>
            </LinkCommandItem>
            <LinkCommandItem closeDialog={closeDialog} to="/plan">
              <PlanIcon className="mr-2 size-4" />
              <span>Plan</span>
            </LinkCommandItem>
            <LinkCommandItem closeDialog={closeDialog} to="/settings">
              <SettingsIcon className="mr-2 size-4" />
              <span>Settings</span>
            </LinkCommandItem>
            <CommandItem
              onSelect={() => {
                logout();
              }}
            >
              <LogoutIcon className="mr-2 size-4" />
              <span>Log out</span>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
}

function LinkCommandItem({
  closeDialog,
  ...props
}: { closeDialog: () => void } & LinkOptions & PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <CommandItem
      onSelect={() => {
        void navigate({ to: props.to });
        closeDialog();
      }}
      asChild
    >
      <Link {...props}>{props.children}</Link>
    </CommandItem>
  );
}
