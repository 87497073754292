import AddNoteIcon from '@/assets/icons/addNote.svg?react';
import PlaybackSpeedIcon from '@/assets/icons/playbackSpeed.svg?react';
import RepeatIcon from '@/assets/icons/repeat.svg?react';
import ShuffleIcon from '@/assets/icons/shuffle.svg?react';
import VolumeIcon from '@/assets/icons/volume.svg?react';

import Spinner from '@/components/misc/spinner';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Slider } from '@/components/ui/slider';
import { Toggle } from '@/components/ui/toggle';
import { TooltipButton, TooltipToggle } from '@/components/ui/tooltip-button';
import { logger } from '@/lib/logger';
import { trackLogSetVolume } from '@/services/analytics-service';
import { useAddAudioNote } from '@/services/notes-service';
import { useAudioStore, useLocalPrefsStore } from '@/stores/audio-store';
import { useFeatureFlag } from '@/stores/feature-flag-store';
import { VolumeX } from 'lucide-react';
import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { useMediaQuery } from 'usehooks-ts';
import PlaybackSpeedDropdown from './playback-speed-dropdown';

function PlaybackOptionsButtonGroup() {
  const [showShuffle] = useFeatureFlag('SHOW_SHUFFLE');
  const [showLoop] = useFeatureFlag('SHOW_LOOP');
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { setVolume, loop } = useGlobalAudioPlayer();
  const playbackRate = useLocalPrefsStore((state) => state.playbackRate);
  const saveVolume = useLocalPrefsStore((state) => state.setVolume);
  const volume = useLocalPrefsStore((state) => state.volume);

  const setAndSaveVolume = useCallback(
    (volume: number) => {
      volume = Math.max(0, Math.min(1, volume));
      setVolume(volume);
      saveVolume(volume);
      trackLogSetVolume({ volume });
    },
    [saveVolume, setVolume],
  );

  const setShuffle = useAudioStore((state) => state.setShuffle);

  useHotkeys(
    'up',
    () => {
      setAndSaveVolume(volume + 0.1);
    },
    [setAndSaveVolume, volume],
  );
  useHotkeys(
    'down',
    () => {
      setAndSaveVolume(volume - 0.1);
    },
    [setAndSaveVolume, volume],
  );

  return (
    <div className="flex items-center text-secondary-foreground">
      {isSmallScreen ? (
        <Button variant="ghost">Options</Button>
      ) : (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <TooltipButton variant="ghost" toolTipContent="Playback Speed">
                <PlaybackSpeedIcon className="text-input" />
                <p className="min-w-8 pl-0.5 text-input">
                  {playbackRate == 1.0 ? '1.0' : playbackRate.toFixed(2)}
                </p>
              </TooltipButton>
            </DropdownMenuTrigger>
            <PlaybackSpeedDropdown />
          </DropdownMenu>

          {showShuffle && (
            <Toggle
              onPressedChange={(val) => {
                setShuffle(val);
              }}
            >
              <ShuffleIcon className="text-input" />
            </Toggle>
          )}
          {showLoop && (
            <TooltipToggle
              onPressedChange={(val) => {
                logger.log('Loop toggled: ', val);
                loop(val);
              }}
              toolTipContent="Loop"
            >
              <RepeatIcon className="text-input" />
            </TooltipToggle>
          )}
          <AddAudioNoteButton />
          <TooltipToggle
            toolTipContent={volume == 0 ? 'Unmute' : 'Mute'}
            pressed={volume == 0}
            onPressedChange={(val) => {
              logger.log('Volume toggled: ', val);
              setAndSaveVolume(val ? 0 : 0.5);
            }}
          >
            {volume == 0 ? (
              <VolumeX className="size-6 text-input" />
            ) : (
              <VolumeIcon className="size-6 text-input" />
            )}
          </TooltipToggle>
          <Slider
            rangeClassName="bg-foreground rounded-full"
            thumbClassName="invisible"
            className="ml-2 w-28 "
            value={[volume]}
            max={1.0}
            min={0}
            step={0.01}
            onValueChange={(val) => {
              setAndSaveVolume(val[0]);
            }}
          />
        </>
      )}
    </div>
  );
}

function AddAudioNoteButton() {
  const { addAudioNote, upsertNoteMutation } = useAddAudioNote();

  return (
    <TooltipButton
      variant="ghost"
      onClick={addAudioNote}
      toolTipContent="Add Note"
    >
      {upsertNoteMutation.isPending ? (
        <Spinner />
      ) : (
        <AddNoteIcon className="text-input" />
      )}
    </TooltipButton>
  );
}

export default PlaybackOptionsButtonGroup;
