import AppleIcon from '@/assets/icons/appleLogo.svg?react';
import LightBulbIcon from '@/assets/icons/lightBulb.svg?react';
import LinkIcon from '@/assets/icons/link.svg?react';
import LogoutIcon from '@/assets/icons/logout.svg?react';
import NewLogoBigIcon from '@/assets/icons/newLogoBig.svg?react';
import personIcon from '@/assets/icons/person.svg';
import PlayIcon from '@/assets/icons/play.svg?react';
import WindowsIcon from '@/assets/icons/windowsLogo.svg?react';
import UserAvatar from '@/components/misc/user-avatar';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useUserStore } from '@/stores/user-store';
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { atom, useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { LoginWebExtension } from './other/login-page';

import { FileUploadScrollable } from '@/components/misc/file-upload-scrollable';
import { UploadDropzone } from '@/components/misc/upload-dropzone';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { hideExtensionTipAtom } from '@/components/web-extension/hide-tip-atom';
import RecentConversionBox from '@/components/web-extension/recent-conversion-box';
import { useLogoutAndClearCache } from '@/hooks/login-logout-hook';
import { cn } from '@/lib/utils';
import {
  sessionConversionHistoryAtom,
  useRequestUrlAudioConversionMutation,
} from '@/services/audio-service';
import {
  useFileUploadStore,
  useUploadDropzone,
} from '@/services/file-upload-service';
import { useUserProfileQuery, useUserToken } from '@/services/user-service';
import { ChevronLeftIcon, Cross1Icon } from '@radix-ui/react-icons';
import { Link, useSearch } from '@tanstack/react-router';
import { atomWithStorage } from 'jotai/utils';

const CHROME_EXTENSION = import.meta.env.VITE_CHROME_EXTENSION === 'true';
const SHOW_RECENT_CONVERSION =
  import.meta.env.VITE_SHOW_RECENT_CONVERSION == 'true';

if (CHROME_EXTENSION) {
  const body = document.body;
  body.style.height = '600px';
  body.style.minHeight = '600px';
  body.style.width = '375px';
  body.style.minWidth = '375px';
  const html = document.documentElement;
  html.style.height = '600px';
  html.style.minHeight = '600px';
  html.style.width = '375px';
  html.style.minWidth = '375px';
}

function WebExtensionRoot() {
  const { isAuthorized } = useUserToken();
  return isAuthorized ? <ExtensionApp /> : <LoginWebExtension />;
}

function ExtensionApp() {
  return (
    <div className="flex size-full flex-col overflow-auto">
      <ExtensionAppBar />
      <ScrollArea
        useFlexFix
        viewPortClassName="[&>div]:grow flex !overflow-y-auto"
        className="grow"
      >
        <ExtensionContent />
      </ScrollArea>
    </div>
  );
}

const pageAtom = atom<'home' | 'settings'>('home');
function ExtensionAppBar() {
  const [currentPage, setCurrentPage] = useAtom(pageAtom);
  return (
    <div className="relative flex items-center justify-center px-4 pt-4 transition-all">
      <Button
        variant={'ghost'}
        className={cn(
          'absolute bottom-auto left-0 top-auto rounded-full opacity-100 transition-all',
          currentPage == 'home' && 'invisible w-0 opacity-0',
        )}
        onClick={() => {
          setCurrentPage('home');
        }}
      >
        <ChevronLeftIcon />
      </Button>
      <NewLogoBigIcon
        height={50}
        width={200}
        className={cn(
          'transition-all',
          currentPage == 'settings' ? 'mx-auto' : 'mx-0',
        )}
      />
      <Button
        className={cn(
          'h-8 w-8 rounded-full p-0 opacity-100 transition-all',
          currentPage == 'settings' ? 'w-0 opacity-0' : 'ml-auto',
        )}
        variant="ghost"
        onClick={() => {
          setCurrentPage('settings');
        }}
      >
        <UserAvatar className={cn('h-8 w-8')} />
      </Button>
    </div>
  );
}

function ExtensionContent() {
  const [currentPage] = useAtom(pageAtom);

  return currentPage == 'settings' ? <ExtensionSettings /> : <ExtensionHome />;
}

type TabPage = 'addUrl' | 'addFile';
const tabNav = atomWithStorage<TabPage>('extensionTabNav', 'addUrl');
function ExtensionHome() {
  const [tabNavValue, setTabNavValue] = useAtom(tabNav);

  return (
    <Tabs
      value={tabNavValue}
      onValueChange={(val) => {
        setTabNavValue(val as TabPage);
      }}
      className="flex h-full flex-col p-4"
    >
      <TabsList className="w-full justify-around bg-transparent">
        <TabsTrigger
          value="addUrl"
          className="grow !rounded-none border-primary px-2 pb-2 pt-0 !shadow-none data-[state=active]:border-b-[1.5px]"
        >
          Add URL
        </TabsTrigger>
        <TabsTrigger
          value="addFile"
          className="grow !rounded-none border-primary px-2 pb-2 pt-0 !shadow-none data-[state=active]:border-b-[1.5px]"
        >
          Add file
        </TabsTrigger>
      </TabsList>
      <ExtensionAddFileTab />
      <ExtensionAddUrlTab />
    </Tabs>
  );
}

function ExtensionSettings() {
  const logout = useLogoutAndClearCache();
  const email = useUserStore((state) => state.user)?.email;
  const userProfileQuery = useUserProfileQuery();
  const [history] = useAtom(sessionConversionHistoryAtom);

  const nameLabel = userProfileQuery.data?.data.first_name ?? email;

  function historyLabel(url?: string) {
    if (!url) return '';
    try {
      const urlObj = new URL(url);
      const hasExt = urlObj.pathname.split('/').pop()?.includes('.') ?? false;
      // return basename if has extension else return url
      return hasExt ? urlObj.pathname.split('/').pop() : url;
    } catch (error) {
      return url;
    }
  }

  return (
    <div className="flex w-full grow flex-col gap-4 overflow-auto p-4 pt-7">
      <div className="flex items-center gap-4">
        <Avatar className="flex size-8 items-center justify-center bg-muted text-sm">
          <AvatarImage src={personIcon} className="size-4" />
        </Avatar>
        <p className="text-sm font-[500] leading-5 text-secondary-foreground">
          {nameLabel}
        </p>
        <Button
          className="ml-auto rounded-full"
          variant="ghost"
          onClick={() => {
            logout();
          }}
        >
          <LogoutIcon className="mr-2 size-4 text-destructive" />
        </Button>
      </div>
      <Separator />
      <div className="flex grow flex-col gap-4 overflow-hidden">
        <p className="text-sm font-[500] leading-5 text-secondary-foreground">
          History
        </p>
        <div className="flex shrink flex-col overflow-hidden rounded-2xl bg-[#F6F6F6]">
          <ScrollArea
            className="flex h-full flex-col"
            viewPortClassName="h-full"
            useBlockFix
          >
            <div className="flex  grow flex-col items-center gap-4 self-stretch p-4">
              {history.length > 0 ? (
                history.map((data, idx) => (
                  <React.Fragment key={data.id}>
                    <Link
                      to="/readalong/$audioId/$chapterId"
                      params={{ audioId: data.id, chapterId: '' }}
                      className="flex w-full items-center justify-start gap-2"
                    >
                      <LinkIcon className="size-4 shrink-0 text-[#6D6D6D]" />
                      <p className="grow truncate pr-2 text-sm font-[500] leading-6 text-[#6D6D6D]">
                        {historyLabel(data.conversionURLOriginal)}
                      </p>
                      <Button
                        variant="ghost"
                        className="ml-auto size-5 shrink-0 rounded-full bg-[#8888] p-0"
                      >
                        <PlayIcon className="size-2 text-white" />
                      </Button>
                    </Link>
                    {idx < history.length - 1 && <Separator />}
                  </React.Fragment>
                ))
              ) : (
                <p className="text-sm font-[500] leading-6 text-[#6D6D6D]">
                  No history yet
                </p>
              )}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}

function ExtensionAddUrlTab() {
  const queryParams: Record<string, string | undefined> = useSearch({
    strict: false,
  });

  const inputRef = React.useRef<HTMLInputElement>(null);
  const mutation = useRequestUrlAudioConversionMutation();

  useEffect(() => {
    const url = queryParams.contentURL;
    if (url && inputRef.current) {
      inputRef.current.value = url;
    }
  }, [queryParams]);

  return (
    <TabsContent
      value="addUrl"
      className="mt-0 flex-col gap-4 p-4 data-[state=active]:flex"
    >
      <Input
        ref={inputRef}
        className="h-12 placeholder:text-[#6b6b6b]"
        placeholder="https://www.example.com"
        icon={<LinkIcon className="text-secondary-foreground" />}
      />
      <Button
        className="h-11 w-full rounded-full font-[700]"
        disabled={!mutation.isIdle}
        onClick={() => {
          if (!inputRef.current?.value) return;
          mutation.mutate(inputRef.current.value, {
            onSettled: () => {
              setTimeout(() => {
                mutation.reset();
              }, 2000);
            },
          });
        }}
      >
        {mutation.isPending && 'Sending'}
        {mutation.isSuccess && 'Sent'}
        {mutation.isError && 'Error'}
        {mutation.isIdle && 'Convert to Audio'}
      </Button>

      {SHOW_RECENT_CONVERSION && <RecentConversionBox />}
      <ExtensionTipBox />
    </TabsContent>
  );
}

function ExtensionAddFileTab() {
  const uploadDropZone = useUploadDropzone({
    showInProgressToast: false,
    location: 'web_extension',
  });
  const mutation = uploadDropZone.uploadMutation;
  const uploads = useFileUploadStore((state) => state.uploads);

  return (
    <TabsContent
      value="addFile"
      className="mb-4 mt-0 h-full flex-col gap-4 p-4 data-[state=active]:flex"
    >
      {uploads.length > 0 ? (
        <FileUploadScrollable
          scrollAreaClassName="px-0"
          className="min-h-24 shrink"
          {...uploadDropZone.getRootProps()}
        />
      ) : (
        <UploadDropzone
          location="web_extension"
          className="mt-0 max-h-[7.75rem] p-0"
          showButton={false}
          showInProgressToast={false}
        />
      )}
      <Button
        className="h-11 w-full rounded-full font-[700]"
        disabled={!mutation.isIdle}
        onClick={(e) => {
          uploadDropZone.getRootProps().onClick?.(e);
        }}
      >
        {mutation.isPending && 'Sending'}
        {mutation.isSuccess && 'Sent'}
        {mutation.isError && 'Error'}
        {mutation.isIdle && 'Convert to Audio'}
      </Button>
      {SHOW_RECENT_CONVERSION && <RecentConversionBox />}
      <ExtensionTipBox />
    </TabsContent>
  );
}

function ExtensionTipBox() {
  const [hideTip, setHideTip] = useAtom(hideExtensionTipAtom);

  return (
    <div
      className={cn(
        'flex shrink-0 flex-col gap-4 overflow-hidden rounded-2xl border-[1.5px] p-4 text-[#636363] transition-all',
        hideTip && 'h-0 border-none p-0',
      )}
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <LightBulbIcon />
          <Button
            variant="ghost"
            className="size-5 rounded-full p-1"
            onClick={() => {
              setHideTip(true);
            }}
          >
            <Cross1Icon className="size-10" />
          </Button>
        </div>
        <p className="text-base font-[600] leading-5 text-[#171717]">Tip</p>
        <p className="text-sm font-[500] leading-5  text-secondary-foreground">
          You can save new URLs with
        </p>
      </div>
      <div className="flex items-center justify-between self-stretch">
        <div className="flex items-center gap-2">
          <WindowsIcon />
          <p className="text-sm font-[500] leading-5">Windows</p>
        </div>
        <p className="ml-auto text-right text-sm font-[500] leading-5 text-secondary-foreground">
          CTRL + Shift + A
        </p>
      </div>
      <div className="flex items-center justify-between self-stretch">
        <div className="flex items-center gap-2">
          <AppleIcon />
          <p className="text-sm font-[500] leading-5">Mac</p>
        </div>
        <p className="ml-auto text-right text-sm font-[500] leading-5 text-secondary-foreground">
          ⌘ + Shift + A
        </p>
      </div>
    </div>
  );
}

export default WebExtensionRoot;
