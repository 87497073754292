import EmailIcon from '@/assets/icons/email.svg?react';
import NewLogoBigIcon from '@/assets/icons/newLogoBig.svg?react';
import PasswordIcon from '@/assets/icons/password.svg?react';
import dashboardSampleImg from '@/assets/imgs/dashboard-sample.png';
import logoSmall from '@/assets/imgs/listeningLogoTransparent.png';
import wavesImg from '@/assets/imgs/waves.png';
import Spinner from '@/components/misc/spinner';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormRootMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useLoginAndNavigate } from '@/hooks/login-logout-hook';
import { cn } from '@/lib/utils';

export function LoginPage() {
  return (
    <section className="flex h-svh w-svw">
      <div className="flex h-full basis-full flex-col justify-center p-8 px-7 pb-36 sm:basis-[46%] sm:pb-64 md:px-14">
        <img
          src={logoSmall}
          alt="Listening logo"
          className={`-ml-1.5 mb-2 mt-8 w-[61px] object-scale-down py-4`}
        />

        <h2 className="mb-2 text-2xl font-[600] leading-6 tracking-[0.48px]">
          Welcome back
        </h2>
        <p className="mb-6 text-lg font-[500] leading-6 tracking-[0.36px] text-secondary-foreground">
          Sign into Listening
        </p>
        <LoginForm />
      </div>
      <div
        className="hidden h-full basis-2/3 items-center justify-end bg-cover bg-no-repeat py-8 pl-8 sm:flex "
        style={{ backgroundImage: `url(${wavesImg})` }}
      >
        <img
          className="max-h-[80%] object-scale-down"
          src={dashboardSampleImg}
        />
      </div>
    </section>
  );
}

export function LoginWebExtension() {
  return (
    <section className="flex size-full max-w-[375px] flex-col overflow-auto">
      <div className="flex size-full max-h-[500px] flex-col gap-4 px-4 py-5">
        <NewLogoBigIcon className="h-[50px] w-auto shrink-0" />
        <LoginForm />
        <p className="mt-auto text-center text-sm font-[500] leading-5 text-secondary-foreground">
          {"Don't have account? "}
          <a
            className="font-[600] text-primary"
            href="https://www.listening.com"
          >
            Create here
          </a>
        </p>
      </div>
    </section>
  );
}

function LoginForm() {
  const { form, onSubmit, loginMutation } = useLoginAndNavigate();
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-full flex-col items-stretch"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  wrapperClassName="w-full"
                  className="placeholder:text-secondary-foreground"
                  placeholder="Email"
                  icon={<EmailIcon className="text-secondary-foreground" />}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  wrapperClassName="w-full mt-4"
                  className="placeholder:text-secondary-foreground"
                  placeholder="Password"
                  type="password"
                  autoComplete="current-password"
                  icon={<PasswordIcon className="text-secondary-foreground" />}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <a
          href="https://www.listening.com/password-reset"
          className="mb-6 ml-auto mt-1 py-2 text-sm font-[500] leading-5 text-secondary-foreground hover:underline"
        >
          Forgot your password?
        </a>
        <Button
          type="submit"
          className={cn(
            'h-[52px] rounded-full text-base font-[600] leading-5',
            loginMutation.isPending ? 'cursor-wait' : 'cursor-pointer',
          )}
          size="lg"
        >
          {loginMutation.isPending ? <Spinner /> : 'Continue with email'}
        </Button>
        <FormRootMessage className="mt-4 text-center" />
      </form>
    </Form>
  );
}
