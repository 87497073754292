import { cn } from '@/lib/utils';
import { useNameAndEmail } from '@/services/user-service';
import type { ComponentProps } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';

function UserAvatar({
  fallbackClassName,
  ...props
}: ComponentProps<typeof Avatar> & { fallbackClassName?: string }) {
  const { letters } = useNameAndEmail();

  return (
    <Avatar {...props}>
      <AvatarImage src="" />
      <AvatarFallback className={cn('text-sm', fallbackClassName)}>
        {letters}
      </AvatarFallback>
    </Avatar>
  );
}

export default UserAvatar;
