import NextIcon from '@/assets/icons/next.svg?react';
import PlayIcon from '@/assets/icons/play.svg?react';
import PrevIcon from '@/assets/icons/prev.svg?react';

import PauseIcon from '@/assets/icons/pause.svg?react';
import {
  DEFAULT_TITLE,
  flatChildren,
  setRealAudioDurationMs,
  useAudioTimeSec,
} from '@/lib/audio-utils';
import { formatTime } from '@/lib/string-utils';
import { cn, useContextAndErrorIfNull } from '@/lib/utils';
import { trackLogPlaybackControlsInteraction } from '@/services/analytics-service';
import { useAudioStore } from '@/stores/audio-store';
import { Link } from '@tanstack/react-router';
import type { ComponentProps } from 'react';
import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { toast } from 'sonner';
import PlaybackOptionsButtonGroup from './audio/playback-options-button-group';
import { CoverImgWithBackground } from './misc/cover-img-with-background';
import { AudioLoadContext } from './misc/providers/audio-load-provider';
import Spinner from './misc/spinner';
import { Button } from './ui/button';
import { Slider } from './ui/slider';
import { TooltipButton } from './ui/tooltip-button';

function PlayBar({ className, ...props }: ComponentProps<'footer'>) {
  return (
    <footer
      className={cn(
        'z-50 col-span-2 flex h-[4.5rem] items-center rounded-t-[3rem] bg-background px-8 shadow-2xl shadow-black',
        className,
      )}
      {...props}
    >
      <AudioPreview />
      <PlaybackControls />
      <SeekBar />
      <PlaybackOptionsButtonGroup />
    </footer>
  );
}

function AudioPreview() {
  const audioItem = useAudioStore((state) => state.audioItem);
  const chapter = useAudioStore((state) => state.chapter);

  const songActive = audioItem?.audioConversionID && chapter?.chapter_id;
  const linkProps: ComponentProps<typeof Link> = songActive
    ? {
        to: '/readalong/$audioId/$chapterId',
        params: {
          audioId: audioItem.audioConversionID,
          chapterId: chapter.chapter_id,
        },
      }
    : {
        to: '/',
      };

  return (
    <Link
      className="hidden w-96 grid-cols-[min-content_1fr] grid-rows-2 items-center gap-x-4 rounded-xl sm:grid"
      {...linkProps}
    >
      <CoverImgWithBackground
        imgSrc={audioItem?.cover_img ?? ''}
        className="row-span-2 size-12 justify-center rounded-xl px-1 pt-1"
      />
      <p
        className={cn(
          `col-start-2 truncate text-sm font-[700]`,
          (!audioItem || !chapter) && 'row-span-2',
        )}
      >
        {audioItem
          ? audioItem.title ?? DEFAULT_TITLE
          : 'Start by uploading a file'}
      </p>
      {audioItem && (
        <p className="col-start-2 truncate text-xs font-[500] text-secondary-foreground">
          {chapter?.title ?? ''}
        </p>
      )}
    </Link>
  );
}

function PlaybackControls() {
  const {
    togglePlayPause: togglePlayPauseState,
    playing,
    isLoading,
  } = useGlobalAudioPlayer();
  const audioItem = useAudioStore((state) => state.audioItem);
  const chapter = useAudioStore((state) => state.chapter);
  const { loadAudio } = useContextAndErrorIfNull(AudioLoadContext);

  const togglePlayPause = useCallback(() => {
    trackLogPlaybackControlsInteraction({
      interaction: playing ? 'pause' : 'play',
    });
    togglePlayPauseState();
  }, [playing, togglePlayPauseState]);

  useHotkeys(
    'space',
    () => {
      togglePlayPause();
    },
    [togglePlayPause],
  );

  return (
    <>
      <TooltipButton
        toolTipContent="Previous Chapter"
        variant="ghost"
        className="ml-auto"
        onClick={() => {
          trackLogPlaybackControlsInteraction({
            interaction: 'skip_backward',
          });
          if (!audioItem || !chapter) {
            toast.info('No audio loaded');
            return;
          }
          const chapters = flatChildren(audioItem.audioConversion.chapters);
          const chapterIdx = chapters.findIndex(
            (c) => c.chapter_id === chapter.chapter_id,
          );
          if (chapterIdx === 0) {
            toast.info('No previous chapter');
            return;
          }
          loadAudio(audioItem, chapters[chapterIdx - 1]);
        }}
      >
        <PrevIcon className="text-input" />
      </TooltipButton>

      <Button
        className="size-12 shrink-0 rounded-full p-2"
        onClick={togglePlayPause}
      >
        {isLoading ? (
          <Spinner />
        ) : playing ? (
          <PauseIcon className="size-4 text-white" />
        ) : (
          <PlayIcon className="size-4 text-white" />
        )}
      </Button>
      <TooltipButton
        toolTipContent="Next Chapter"
        variant="ghost"
        onClick={() => {
          trackLogPlaybackControlsInteraction({
            interaction: 'skip_forward',
          });
          if (!audioItem || !chapter) {
            toast.info('No audio loaded');
            return;
          }
          const chapters = flatChildren(audioItem.audioConversion.chapters);
          const chapterIdx = chapters.findIndex(
            (c) => c.chapter_id === chapter.chapter_id,
          );
          if (chapterIdx === chapters.length - 1) {
            toast.info('No next chapter');
            return;
          }
          loadAudio(audioItem, chapters[chapterIdx + 1]);
        }}
      >
        <NextIcon className="text-input" />
      </TooltipButton>
    </>
  );
}

export function SeekBar({
  includeTimestamps = true,
  className,
  trackClassName,
}: {
  includeTimestamps?: boolean;
  trackClassName?: string;
  className?: string;
}) {
  const time = useAudioTimeSec();
  const { duration, seek } = useGlobalAudioPlayer();

  useEffect(() => {
    const audioItem = useAudioStore.getState().audioItem;
    const chapter = useAudioStore.getState().chapter;
    if (!audioItem || !chapter) return;
    setRealAudioDurationMs({ audioItem, chapter }, duration * 1000);
  }, [duration]);

  const formattedTime = formatTime(time);
  const formattedDuration = formatTime(duration);

  useHotkeys(
    'left',
    () => {
      seek(time - 5);
    },
    [seek, time],
  );
  useHotkeys(
    'right',
    () => {
      seek(time + 5);
    },
    [seek, time],
  );

  return (
    <>
      {includeTimestamps && (
        <p className="hidden w-12 shrink-0 whitespace-nowrap text-center text-xs font-[500] text-secondary-foreground sm:block">
          {formattedTime}
        </p>
      )}

      <Slider
        trackClassName={trackClassName}
        className={cn('mx-2 h-1.5 basis-full sm:basis-1/2', className)}
        thumbClassName="invisible"
        max={duration}
        min={0}
        onValueChange={(val) => {
          trackLogPlaybackControlsInteraction({
            interaction: 'seek',
          });
          seek(val[0]);
        }}
        value={[time]}
      />
      {includeTimestamps && (
        <p className="hidden w-12 shrink-0 whitespace-nowrap text-center text-xs font-[500] text-secondary-foreground sm:block">
          {formattedDuration}
        </p>
      )}
    </>
  );
}

export default PlayBar;
