import { cn } from '@/lib/utils';
import { Img } from 'react-image';
import { Skeleton } from '../ui/skeleton';

export function CoverImgWithBackground({
  imgSrc,
  className,
  imgClassName,
}: {
  imgSrc: string;
  className?: string;
  imgClassName?: string;
}) {
  return (
    <div
      className={cn(
        'flex aspect-square  w-full shrink-0 flex-col items-center overflow-clip bg-gradient-to-r from-cyan-500  to-blue-500',
        className,
      )}
    >
      <Img
        src={imgSrc}
        className={cn('mt-4 w-5/6 rounded-md', imgClassName)}
        decode={false}
        loader={
          <Skeleton className="mt-4 h-full w-5/6 rounded-md rounded-b-none" />
        }
      />
    </div>
  );
}
