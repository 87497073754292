import { useEffect } from 'react';
import { useDarkMode } from 'usehooks-ts';

export function ThemeProvider({ children }: React.PropsWithChildren) {
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');

    root.classList.add(isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return <>{children}</>;
}
