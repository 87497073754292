import type { InitOptions } from '@usersnap/browser';
import { loadSpace } from '@usersnap/browser';
import React, { useContext, useEffect, useState } from 'react';

const usersnapApiKey = import.meta.env.VITE_USERSNAP_SPACE_API_KEY as string;

export const UsersnapContext = React.createContext<UsersnapApi | null>(null);

type UsersnapApi = Awaited<ReturnType<typeof loadSpace>>;

export const UsersnapProvider = ({
  initParams,
  children,
}: { initParams?: InitOptions } & React.PropsWithChildren) => {
  const [usersnapApi, setUsersnapApi] = useState<UsersnapApi | null>(null);

  useEffect(() => {
    if (import.meta.env.VITE_CHROME_EXTENSION === 'true') return;
    void loadSpace(usersnapApiKey).then((api) => {
      void api.init(initParams);
      setUsersnapApi(api);
    });
  }, [initParams]);

  return (
    <UsersnapContext.Provider value={usersnapApi}>
      {children}
    </UsersnapContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
