import { Toaster } from '@/components/ui/sonner';
import { logger } from '@/lib/logger';
import { useFeatureFlag } from '@/stores/feature-flag-store';
import { useUserStore } from '@/stores/user-store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import type React from 'react';
import { UsersnapProvider } from '../../providers/usersnap-provider';
import { TooltipProvider } from '../../ui/tooltip';
import { AudioLoadProvider } from './audio-load-provider';
import { ThemeProvider } from './theme-provider';
const inlineCommitHash = import.meta.env.VITE_COMMIT_HASH as string | undefined;

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: (failureCount, error) => {
        logger.error(`Mutation failed #${failureCount.toString()}`, error);
        return false;
      },
    },
    queries: {
      retry: (failureCount, error) => {
        const shouldRetry = failureCount < 1;
        logger.error(
          `Query failed #${failureCount.toString()}${shouldRetry ? ' Retrying...' : ''}`,
          error,
        );

        return shouldRetry;
      },
    },
  },
});

const storedCommitHash = localStorage.getItem('commitHash');
const shouldPurge =
  inlineCommitHash && storedCommitHash && storedCommitHash !== inlineCommitHash;
logger.log('App Versions:', {
  inline: inlineCommitHash,
  localStorage: storedCommitHash,
  shouldPurge,
});
if (shouldPurge) {
  logger.log('Purging query cache due to version change');
  void queryClient.invalidateQueries({ refetchType: 'all' });
}
if (inlineCommitHash && storedCommitHash !== inlineCommitHash) {
  try {
    localStorage.setItem('commitHash', inlineCommitHash);
  } catch (e) {
    logger.error('Failed to update localStorage commitHash', e);
  }
}

export function Providers({ children }: React.PropsWithChildren) {
  const [showTanstackQueryDevtools] = useFeatureFlag('TANSTACK_QUERY_DEVTOOLS');
  const [showTanstackRouterDevtools] = useFeatureFlag(
    'TANSTACK_ROUTER_DEVTOOLS',
  );

  const user = useUserStore((state) => state.user);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AudioLoadProvider>
          <ThemeProvider>
            <UsersnapProvider
              initParams={{
                user: user
                  ? {
                      email: user.email,
                      userId: user.userId,
                    }
                  : undefined,
              }}
            >
              <TooltipProvider delayDuration={300}>
                {showTanstackQueryDevtools && (
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    buttonPosition="bottom-left"
                  />
                )}
                {showTanstackRouterDevtools && <TanStackRouterDevtools />}
                <Toaster />
                {children}
              </TooltipProvider>
            </UsersnapProvider>
          </ThemeProvider>
        </AudioLoadProvider>
      </QueryClientProvider>
    </>
  );
}
