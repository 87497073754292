import { logger } from '@/lib/logger';
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type User = {
  email: string;
  token: string;
  rssUrl: string;
  userId: string;
};

type UserStoreState = {
  user: User | null;
  login: (user: User) => void;
  logout: () => void;
};

export const useUserStore = create<UserStoreState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        login: (user) => {
          logger.log('saving user to user store cache');
          useUserStore.persist.clearStorage();
          set({ user });
        },
        logout: () => {
          logger.log('clearing user from user store cache');
          useUserStore.persist.clearStorage();
          set({ user: null });
        },
      }),
      {
        name: 'user-storage',
      },
    ),
  ),
);

export const isUserLoggedIn = () => {
  const user = useUserStore.getState().user;
  return user?.token && user.userId;
};
