import type { paths } from '@/api/v1';
import type {
  AudioConversionDTO,
  AudioItemDTO,
  TagDTO,
} from '@/lib/audio-utils';
import { ApiException } from '@/lib/exceptions';
import { logger } from '@/lib/logger';
import { isError } from '@/lib/utils';
import { useFeatureFlagStore } from '@/stores/feature-flag-store';
import { useUserStore } from '@/stores/user-store';
import { Mutex } from 'async-mutex';
import type { Stripe } from 'stripe';
import { z } from 'zod';
import {
  mockSubscriptionSummaryResponses,
  useMockSubStore,
} from './mock-api-responses';

export const BASE_URL = import.meta.env.VITE_BACKEND_URL as string;
const inlineCommitHash = import.meta.env.VITE_COMMIT_HASH as string | undefined;

type RequestProps<P> = {
  path: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  token?: string;
  payload?: P;
  params?: Record<string, string | number | boolean | undefined>;
  timeoutMs?: number;
  cache?: RequestCache;
};

function urlCanParse(url: string | URL, base?: string | URL | undefined) {
  try {
    new URL(url.toString(), base);
    return true;
  } catch (error) {
    return false;
  }
}

const BackendErrorResponseSchema = z.object({
  error_reason: z.string(),
  error_type: z.string(),
  // status: z.literal('failed'),
  // status_code: z.number(),
});

async function request<P>({
  path,
  method,
  token,
  payload,
  params,
  timeoutMs = 30 * 1000,
  cache = 'reload',
}: RequestProps<P>) {
  if (!urlCanParse(path, BASE_URL)) {
    throw new ApiException(`Invalid path "${path}"`, 'INVALID_URL');
  }

  const url = new URL(path, BASE_URL);

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (value === undefined) continue;
      url.searchParams.append(key, String(value));
    }
  }

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Listening-Webapp-Version': inlineCommitHash ?? 'v0',
  };

  if (token) headers.Authorization = token;

  let response: Response;
  const abortController = new AbortController();
  if (timeoutMs)
    setTimeout(() => {
      abortController.abort();
    }, timeoutMs);
  try {
    response = await fetch(url.toString(), {
      headers,
      method,
      body: payload ? JSON.stringify(payload) : undefined,
      signal: abortController.signal,
      cache,
    });
  } catch (error) {
    if (isError(error) && error.name === 'AbortError') {
      throw new ApiException(
        `${method} to ${url.toString()} failed with timeout error`,
        'TIMEOUT',
        undefined,
        error,
      );
    }

    const message = error instanceof Error ? error.message : String(error);
    throw new ApiException(
      `${method} to ${url.toString()} failed with unknown error: ${message}`,
      'FETCH_ERROR',
      undefined,
      error,
    );
  }

  // Raise if status code is not 2XX
  if (response.status >= 400) {
    if (response.status === 401) useUserStore.getState().logout();
    if (response.status === 429)
      throw new ApiException(
        `Too many requests`,
        'TOO_MANY_REQUESTS',
        response,
      );

    const type = response.status <= 499 ? 'CLIENT_ERROR' : 'SERVER_ERROR';
    try {
      const errorResponse = (await response.json()) as unknown;
      const validatedErrorResponse =
        BackendErrorResponseSchema.parse(errorResponse);
      throw new ApiException(
        validatedErrorResponse.error_reason,
        type,
        response,
        undefined,
        validatedErrorResponse,
      );
    } catch (e) {
      if (e instanceof ApiException) throw e;
      if (e instanceof z.ZodError) {
        logger.error(
          'Error response from backend did not match schema',
          e.errors,
        );
      } else {
        logger.error('Error parsing error response from backend', e);
      }
      throw new ApiException(
        `${method} to ${url.toString()} failed with status ${response.status.toString()}`,
        type,
        response,
      );
    }
  }

  // Get the response data
  // Response type should be arbitrary JSON
  type ResponseData = Record<string, unknown>;
  let data: ResponseData | undefined;
  try {
    data =
      response.headers.get('content-type') == 'application/json'
        ? ((await response.json()) as typeof data)
        : undefined;
  } catch (error) {
    throw new ApiException(
      `${method} to ${url.toString()} returned invalid JSON response"`,
      'INVALID_JSON',
    );
  }

  return data;
}

const get = async <P>(props: Omit<RequestProps<P>, 'method' | 'payload'>) =>
  request({ method: 'GET', ...props });
const post = async <P>(props: Omit<RequestProps<P>, 'method'>) =>
  request({ method: 'POST', ...props });
const deleteReq = async <P>(props: Omit<RequestProps<P>, 'method'>) =>
  request({ method: 'DELETE', ...props });
const patch = async <P>(props: Omit<RequestProps<P>, 'method'>) =>
  request({ method: 'PATCH', ...props });

export default { get, post };

export type PaymentDetailsDTO =
  | {
      nextPaymentDate: string;
      status: 'success';
    }
  | {
      status: 'failed';
    };

type LoginDTO = {
  Authorization: string;
  _id: string;
  email: string;
  podcastURL: string;
  remainingCredits: number;
  spotifyURL: string;
  status: string;
};

export function loginReq(email: string, password: string): Promise<LoginDTO> {
  return post({
    path: '/user/login',
    payload: {
      email,
      password,
    },
  }) as Promise<LoginDTO>;
}

export function registerReq(
  email: string,
  password: string,
): Promise<LoginDTO> {
  return post({
    path: '/user/register',
    payload: {
      email,
      password,
    },
  }) as Promise<LoginDTO>;
}

export function registerWithStripeSetupIntentReq(args: {
  email: string;
  password: string;
  stripeSetupIntentId: string;
  growsurfReferrerId?: string;
  utmParams?: Record<string, string>;
}): Promise<LoginDTO> {
  return post({
    path: '/user/registerWithStripeSetupIntent',
    payload: {
      ...args.utmParams,
      email: args.email,
      password: args.password,
      stripeSetupIntentId: args.stripeSetupIntentId,
      growsurfReferrerId: args.growsurfReferrerId,
    },
  }) as Promise<LoginDTO>;
}

export function deleteAccountReq(token: string): Promise<LoginDTO> {
  return post({
    path: '/user/deleteUser',
    token,
  }) as Promise<LoginDTO>;
}

type PdfUploadUrlDTO = {
  signed_upload_url: string;
  fields: Record<string, string>;
};

export function createPdfUploadUrlReq(
  filename: string,
  token: string,
): Promise<PdfUploadUrlDTO> {
  logger.log(`Creating upload url for ${filename}`);
  return post({
    path: '/pdf/get-upload-url',
    payload: { filename },
    token,
  }) as unknown as Promise<PdfUploadUrlDTO>;
}

export async function uploadFileToS3Req(
  file: File,
  signedUrl: string,
  fields: Record<string, string>,
  onProgress?: (progress: number) => void,
  timeoutMs?: number,
): Promise<string> {
  logger.log(`Uploading file ${file.name} to ${signedUrl}`);

  const formData = new FormData();
  for (const [key, value] of Object.entries(fields)) {
    formData.append(key, value);
  }
  formData.append('file', file);

  const xhr = new XMLHttpRequest();

  let timeoutId: NodeJS.Timeout | null = null;

  xhr.upload.onprogress = (event) => {
    if (!event.lengthComputable) return;
    const progress = Math.round((event.loaded * 100) / event.total);
    if (onProgress) onProgress(progress);

    if (timeoutId) clearTimeout(timeoutId);
    if (timeoutMs)
      timeoutId = setTimeout(() => {
        xhr.abort();
        throw new Error('Upload timed out');
      }, timeoutMs);
  };

  xhr.open('POST', signedUrl, true);

  const resp = await new Promise<XMLHttpRequest>((resolve, reject) => {
    xhr.onload = () => {
      resolve(xhr);
    };
    xhr.onerror = (progress) => {
      reject(
        new Error('XMLHttpRequest error', {
          cause: {
            xhr,
            progress,
          },
        }),
      );
    };
    xhr.send(formData);
  });
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (timeoutId != null) clearTimeout(timeoutId);

  const locationHeader = resp.getResponseHeader('location');
  if (!locationHeader) throw new Error('No location header in response');
  return locationHeader;
}

export async function convertPdfAudioReq(
  documentUrl: string,
  token: string,
): Promise<AudioConversionDTO> {
  logger.log(`Converting audio for ${documentUrl}`);
  const data = (await post({
    path: '/pdf/convert-audio',
    payload: {
      url: documentUrl,
    },
    token,
  })) as unknown as { audioConversion: AudioConversionDTO };
  return data.audioConversion;
}

export async function convertUrlAudioReq(
  url: string,
  token: string,
): Promise<AudioConversionDTO> {
  logger.log(`Converting audio for ${url}`);
  const data = (await post({
    path: '/audio/convert-url',
    payload: {
      url: url,
    },
    token,
  })) as unknown as { audioConversion: AudioConversionDTO };
  return data.audioConversion;
}

export type ProcessingItemStatus =
  | 'initializing'
  | 'incomplete'
  | 'downloadedPDF'
  | 'deleted'
  | 'completedExtraction'
  | 'complete'
  | 'Error'
  | 'Error_Not_Enough_Credit';
export type ProcessingQueueItemDTO = {
  id: string;
  status: ProcessingItemStatus;
  title?: string;
};
export function getProcessingQueueReq(token: string): Promise<{
  processingQueue: ProcessingQueueItemDTO[];
}> {
  return get({
    path: '/audio/getProcessingQueue',
    token,
  }) as unknown as Promise<{
    processingQueue: ProcessingQueueItemDTO[];
  }>;
}

export async function listAudioItemsReq(token: string): Promise<{
  data: AudioItemDTO[];
}> {
  try {
    const result = (await get({
      path: '/audio/',
      token,
    })) as unknown as {
      data: AudioItemDTO[];
    };
    logger.log(
      `GET /audio/ completed returned ${result.data.length.toString()}`,
      new Date(),
    );
    return result;
  } catch (error) {
    if (
      error instanceof ApiException &&
      error.type === 'CLIENT_ERROR' &&
      error.response?.status === 404
    ) {
      logger.warn('GET /audio/ returned 404, logging out user');
      useUserStore.getState().logout();
      return { data: [] };
    }
    throw error;
  }
}

export async function getAudioItemReq(
  token: string,
  audioId: string,
): Promise<AudioItemDTO> {
  const result = (await get({
    path: `/audio/${audioId}`,
    token,
  })) as unknown as {
    data: AudioItemDTO;
  };
  logger.log(`GET /audio/${audioId} completed`);
  return result.data;
}

export function updateAudioItemReq(
  token: string,
  audioId: string,
  tags?: TagDTO[],
  title?: string,
): Promise<{
  data: AudioItemDTO;
}> {
  return patch({
    path: `/audio/${audioId}`,
    payload: {
      ...(tags && { tags }),
      ...(title && { title }),
    },
    token,
  }) as unknown as Promise<{
    data: AudioItemDTO;
  }>;
}

export function deleteAudioItemReq(
  token: string,
  audioId: string,
): Promise<{
  data: AudioItemDTO;
}> {
  return post({
    path: `/audio/deleteAudioItem`,
    payload: {
      audioConversionId: audioId,
    },
    token,
  }) as unknown as Promise<{
    data: AudioItemDTO;
  }>;
}

export type ChapterImageDTO = {
  height: number;
  type: 'figure' | 'formula';
  url: string;
  width: number;
  word_index: number;
};

export type ChapterDetailsDTO = {
  id: string;
  images: ChapterImageDTO[];
  updated_at_ms: number;
  is_preview: boolean;
  timestamps: {
    paragraph_timestamps: {
      end_ms: number;
      start_ms: number;
      sentence_timestamps: {
        coords?: {
          coords: [number, number, number, number];
          page: number;
        }[];
        end_ms: number;
        start_ms: number;
        word_timestamps: {
          coords?: {
            coords: [number, number, number, number];
            page: number;
          }[];
          end_ms: number;
          start_ms: number;
          text: string;
          page: number;
        }[];
      }[];
    }[];
  };
};

const mutex = new Mutex();
export async function getChapterDetailsReq(
  token: string,
  audioId: string,
  chapterId: string,
): Promise<ChapterDetailsDTO> {
  return await mutex.runExclusive(async () => {
    const result = (await get({
      path: `/audio/${audioId}/chapter/${chapterId}`,
      token,
      cache: 'default',
    })) as unknown as {
      chapter_detail: ChapterDetailsDTO;
    };
    logger.log(`GET /audio/${audioId}/chapter/${chapterId} completed`);
    return result.chapter_detail;
  });
}

export function getStripeCustomerPaymentMethods(token: string): Promise<{
  data: Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>>;
}> {
  return post({
    path: `/stripe/get-customer-payment-methods`,
    token,
  }) as unknown as Promise<{
    data: Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>>;
  }>;
}

export type UserProfileDTO = {
  first_name?: string;
  last_name?: string;
};
export function getUserProfileReq(
  token: string,
): Promise<{ data: UserProfileDTO }> {
  return get({
    path: '/user/profile',
    token,
  }) as unknown as Promise<{ data: UserProfileDTO }>;
}

export function patchUserProfileReq(
  token: string,
  changes: Partial<UserProfileDTO>,
): Promise<{ data: UserProfileDTO }> {
  return patch({
    path: '/user/profile',
    token,
    payload: {
      updates: changes,
    },
  }) as unknown as Promise<{ data: UserProfileDTO }>;
}

export type ListeningVoice = 'AMY' | 'DAN' | 'LIV' | 'SCARLETT' | 'WILL';
export type NoteCaptureLength = 'sentence' | 'paragraph';
export type UserPreferencesDTO = {
  voice: ListeningVoice;
  audio_note_capture: NoteCaptureLength;
};
export async function getUserPreferencesReq(
  token: string,
): Promise<UserPreferencesDTO> {
  const results = (await get({
    path: '/user/preferences',
    token,
  })) as unknown as UserPreferencesDTO;
  return {
    ...results,
    audio_note_capture:
      (results.audio_note_capture as NoteCaptureLength | undefined | null) ??
      (localStorage.getItem('audio_note_capture') as
        | 'sentence'
        | 'paragraph'
        | null) ??
      'sentence',
  };
}

export function updateUserPreferencesReq(
  token: string,
  updates: Partial<UserPreferencesDTO>,
): Promise<{ data: UserPreferencesDTO }> {
  localStorage.setItem('audio_note_capture', updates.audio_note_capture ?? '');
  return post({
    path: '/user/preferences',
    token,
    payload: {
      updates,
    },
  }) as unknown as Promise<{ data: UserPreferencesDTO }>;
}

export type Note = {
  audio_conversion_id: string;
  body: string;
  created_at_ms?: number;
  deleted_at_ms?: number;
  id: string;
  title: string;
  updated_at_ms?: number;
  user_id: string;
  chapter_id?: string;
  chapter_audio_offset_ms?: number;
};

export type ListNotesReqOptions = {
  audioConversionId?: string;
  limit?: number;
  cursorId?: string | null;
  includeDeleted?: boolean;
};

export async function listNotesReq(
  token: string,
  opts?: ListNotesReqOptions,
): Promise<{ notes: Note[]; cursorId: string | null }> {
  const result = (await get({
    path: '/notes/',
    token,
    params: {
      audio_conversion_id: opts?.audioConversionId,
      cursor_id: opts?.cursorId ? opts.cursorId : undefined,
      include_deleted: opts?.includeDeleted,
      limit: opts?.limit,
    },
  })) as unknown as {
    data: {
      notes: Note[];
      cursor_id?: string;
    };
  };

  return {
    notes: result.data.notes,
    cursorId: result.data.cursor_id ?? null,
  };
}

export function upsertNotesReq(
  token: string,
  notes: Omit<Note, 'id'>[],
): Promise<{
  data: Note[];
}> {
  return post({
    path: '/notes/upsert-many',
    token,
    payload: {
      notes,
    },
  }) as unknown as Promise<{
    data: Note[];
  }>;
}

export async function deleteNoteReq({
  token,
  noteId,
}: {
  token: string;
  noteId: string;
}): Promise<void> {
  await deleteReq({
    path: `/notes/${noteId}`,
    token,
  });
}

export async function modifyStripeSubscriptionReq({
  token,
  stripePriceId,
}: {
  token: string;
  stripePriceId: string;
}): Promise<void> {
  await post({
    path: '/stripe/modify-subscription',
    payload: {
      stripe_price_id: stripePriceId,
    },
    token,
  });
}

export type SubscriptionSummaryDTO =
  paths['/payment/subscriptions']['get']['responses']['200']['content']['application/json'];
export async function getSubscriptionsReq(
  token: string,
): Promise<SubscriptionSummaryDTO> {
  const shouldMock =
    useFeatureFlagStore.getState().flags.MOCK_SUBSCRIPTION_ENDPOINT;
  const chosenMock = useMockSubStore.getState().chosenMock;
  if (shouldMock && chosenMock !== 'mock_disabled') {
    await new Promise((resolve) => setTimeout(resolve, 100));
    return mockSubscriptionSummaryResponses[chosenMock];
  }
  return get({
    path: '/payment/subscriptions',
    token,
  }) as unknown as Promise<SubscriptionSummaryDTO>;
}

export type UserSummaryDTO = {
  email: string;
  id: string;
  metering: {
    is_active_subscription: boolean;
  };
  onboarding: {
    is_complete: boolean;
  };
};
export async function getUserSummaryReq(token: string) {
  return get({
    path: '/user/summary',
    token,
  }) as unknown as Promise<UserSummaryDTO>;
}
export async function getPaymentDetailsReq(token: string) {
  return get({
    path: '/money/getPaymentDetails',
    token,
  }) as unknown as Promise<PaymentDetailsDTO>;
}

export async function cancelStripeSubscriptionReq(token: string) {
  await post({
    path: '/stripe/cancel-subscription',
    token,
  });
}

export async function postCancelSurveyReq(
  token: string,
  survey: {
    email: string;
    is_user_deleted: boolean;
    user_cancel_reason: string;
    user_liked_features: string;
  },
) {
  await post({
    payload: {
      data: survey,
    },
    path: '/user/post-cancel-survey',
    token,
  });
}

export async function modifyStripePaymentMethodReq({
  token,
  paymentMethodId,
}: {
  token: string;
  paymentMethodId: string;
}) {
  await post({
    path: '/stripe/modify-payment-method',
    payload: {
      payment_method_id: paymentMethodId,
    },
    token,
  });
}

export async function resubscribeStripeSubscriptionReq({
  token,
  price_id,
}: {
  token: string;
  price_id: string;
}) {
  return (await post({
    path: '/stripe/resubscribe',
    token,
    payload: {
      price_id,
    },
  })) as unknown as Promise<{ payment_intent_client_secret: string | null }>;
}

export async function successfulPaymentIntentReq({
  token,
  payment_intent_id,
}: {
  token: string;
  payment_intent_id: string;
}) {
  await post({
    path: '/stripe/successful-payment-intent',
    token,
    payload: {
      payment_intent_id,
    },
  });
}
