import { trackLogSetPlaybackSpeed } from '@/services/analytics-service';
import { useLocalPrefsStore } from '@/stores/audio-store';
import { useCallback } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { Button } from '../ui/button';
import { DropdownMenuContent } from '../ui/dropdown-menu';
import { Slider } from '../ui/slider';
import { Toggle } from '../ui/toggle';

const MIN_RATE = 0.25;
const MAX_RATE = 4.0;

function useSetPlaybackRate() {
  const setPlaybackRate = useLocalPrefsStore((state) => state.setPlaybackRate);
  const { setRate } = useGlobalAudioPlayer();
  return useCallback(
    (rate: number) => {
      if (rate < MIN_RATE) rate = MIN_RATE;
      if (rate > MAX_RATE) rate = MAX_RATE;
      setPlaybackRate(rate);
      setRate(rate);
    },
    [setPlaybackRate, setRate],
  );
}

// function PlaybackSpeedDropdown() {
//   const setAndSavePlaybackRate = useSetPlaybackRate();
//   return (
//     <DropdownMenuContent>
//       <DropdownMenuLabel>Playback Speed</DropdownMenuLabel>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem onClick={() => setAndSavePlaybackRate(0.5)}>
//         0.5
//       </DropdownMenuItem>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem onClick={() => setAndSavePlaybackRate(0.75)}>
//         0.75
//       </DropdownMenuItem>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem onClick={() => setAndSavePlaybackRate(1)}>
//         Normal
//       </DropdownMenuItem>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem onClick={() => setAndSavePlaybackRate(1.25)}>
//         1.25
//       </DropdownMenuItem>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem onClick={() => setAndSavePlaybackRate(1.5)}>
//         1.5
//       </DropdownMenuItem>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem onClick={() => setAndSavePlaybackRate(2)}>
//         2
//       </DropdownMenuItem>
//     </DropdownMenuContent>
//   );
// }
function PlaybackSpeedDropdown() {
  const setAndSavePlaybackRateState = useSetPlaybackRate();
  const playbackRate = useLocalPrefsStore((state) => state.playbackRate);

  const setAndSavePlaybackRate = useCallback(
    (val: number) => {
      setAndSavePlaybackRateState(val);
      trackLogSetPlaybackSpeed({ rate: val });
    },
    [setAndSavePlaybackRateState],
  );

  return (
    <DropdownMenuContent className="flex flex-col gap-4 p-4">
      <div className="flex justify-between">
        <span className="text-lg font-[600]">Playback Speed</span>
        <span className="text-lg font-[600]">{`${playbackRate.toFixed(
          2,
        )}x`}</span>
      </div>
      <div className="flex gap-2">
        <Button
          variant="outline"
          className="size-8 rounded-full"
          onClick={() => {
            setAndSavePlaybackRate(playbackRate - 0.05);
          }}
        >
          <span className="text-2xl">-</span>
        </Button>
        <Slider
          value={[playbackRate]}
          min={MIN_RATE}
          max={MAX_RATE}
          step={0.05}
          onValueChange={(val) => {
            setAndSavePlaybackRate(val[0]);
          }}
        />
        <Button
          variant="outline"
          className="size-8 rounded-full"
          onClick={() => {
            setAndSavePlaybackRate(playbackRate + 0.05);
          }}
        >
          <span className="text-2xl">+</span>
        </Button>
      </div>
      <div className="flex">
        <Toggle
          pressed={playbackRate.toFixed(2) == '0.80'}
          onClick={() => {
            setAndSavePlaybackRate(0.8);
          }}
        >
          0.8
        </Toggle>
        <Toggle
          pressed={playbackRate.toFixed(2) == '0.90'}
          onClick={() => {
            setAndSavePlaybackRate(0.9);
          }}
        >
          0.9
        </Toggle>
        <Toggle
          pressed={playbackRate.toFixed(2) == '1.00'}
          onClick={() => {
            setAndSavePlaybackRate(1.0);
          }}
        >
          1.0
        </Toggle>
        <Toggle
          pressed={playbackRate.toFixed(2) == '1.30'}
          onClick={() => {
            setAndSavePlaybackRate(1.3);
          }}
        >
          1.3
        </Toggle>
        <Toggle
          pressed={playbackRate.toFixed(2) == '1.50'}
          onClick={() => {
            setAndSavePlaybackRate(1.5);
          }}
        >
          1.5
        </Toggle>
        <Toggle
          pressed={playbackRate.toFixed(2) == '2.00'}
          onClick={() => {
            setAndSavePlaybackRate(2.0);
          }}
        >
          2.0
        </Toggle>
      </div>
    </DropdownMenuContent>
  );
}

export default PlaybackSpeedDropdown;
